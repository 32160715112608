import ROUTES from 'core/modules/router'
import { i18n } from 'translate/i18n'
import CodeOfConductURL from 'openspace/assets/clients/oken/docs/codigo-conduta.pdf'
import MaterialURL from 'openspace/assets/clients/oken/docs/material-didatico.pdf'
import { LinkItemData } from './Footer.types'

interface Links {
  left: LinkItemData[]
  right: LinkItemData[]
}

export const LinksIntl: () => Links = () => ({
  left: [
    {
      title: `${i18n.t('components.footer.data.collection')}`,
      url: ROUTES.authenticated.myCollection,
    },
  ],
  right: [
    {
      title: `${i18n.t('components.footer.data.termOfUse')}`,
      url: ROUTES.unauthenticated.terms,
    },
    {
      title: `${i18n.t('components.footer.data.conductCode')}`,
      file: CodeOfConductURL,
    },
    {
      title: `${i18n.t('components.footer.data.material')}`,
      file: MaterialURL,
    },
  ],
})
