import React from 'react'
import { CommunicationViewProps } from './Communication.types'
import Styles from './Communication.module.scss'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Card from 'components/Card/Card'
import Switch from '@onepercentio/one-ui/dist/components/Switch/Switch'
import Button from '@onepercentio/one-ui/dist/components/Button/Button'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import { t } from 'translate/i18n'
import IconRarum from 'components/IconRarum/IconRarum.view'

/**
 * Setup communication and account related items
 **/
export default function CommunicationView({
  onDataUsage,
  dataUsage,
  onCancelAccount,
}: CommunicationViewProps) {
  return (
    <>
      <Text
        code='authenticated.userProfile.communication.sections.0'
        type={FigmaTypo.H3}
      />
      <Spacing size='16' />
      <Card>
        <div className={Styles.row}>
          <Text
            code='authenticated.userProfile.communication.dataUsage'
            type={FigmaTypo.H4}
          />
          <Switch enabled={dataUsage} onToggle={onDataUsage} size={24} />
        </div>
      </Card>
      <Spacing size='32' />
      <Spacing size='32' />
      <Text
        code='authenticated.userProfile.communication.sections.1'
        type={FigmaTypo.H3}
      />
      <Spacing size='16' />
      <Card>
        <div className={Styles.row}>
          <Text
            code='authenticated.userProfile.communication.acceptCookies'
            type={FigmaTypo.H4}
          />
          <Switch enabled={true} disabled size={24} onToggle={() => {}} />
        </div>
      </Card>
      <Spacing size='16' />
      <Card>
        <div className={Styles.row}>
          <div>
            <Text
              code='authenticated.userProfile.communication.cancel.title'
              type={FigmaTypo.H4}
            />
            <Text
              code='authenticated.userProfile.communication.cancel.description'
              type={FigmaTypo.P14}
            />
          </div>
          <Button
            variant='transparent-link'
            color='primary'
            onClick={onCancelAccount}>
            {t('authenticated.userProfile.communication.cancel.actions.cancel')}
            <IconRarum icon='chevronRight' size={24} />
          </Button>
        </div>
      </Card>
    </>
  )
}
