import { OmitDeep } from 'type-fest'
import messagesEn from './en.ts'
import { merge } from 'lodash'

const messages = {
  'en-US': {
    translations: {
      generic: {
        brandMotto: 'This is rare. This is Oken.',
      },
      offerDetails: {
        goalReached_0: 'No quotas reserved at the moment',
      },
      home: {
        banner: {
          v2: {
            brand: 'oken',
          },
          title: 'INVEST WITH OKEN',
          subtitle:
            'Tokenized assets marketplace customized for your community',
        },
        sections: {
          gallery: {
            heading: 'Meet the token issuers',
            title: 'Token issuers',
            action: {
              seeAll: 'See all issuers',
            },
          },
          create: {
            title: '',
          },
          brand: {
            description: `We offset all carbon emissions from the platform and traded assets.

Oken also operates on blockchains that are efficient in their greenhouse gas emissions.`,
          },
        },
      },
      allGalleries: {
        title: 'Token Issuers',
        sections: {
          highlight: {
            title: 'Featured token issuers',
          },
          createYourOwn: {
            title: '',
          },
        },
      },
      galleryDetails: {
        noMarket: 'Issuer items',
        noAvailable: "Stay tuned!\nWe'll soon have offers from this issuer",
      },
      topbar: {
        menus: {
          galleries: 'ISSUERS',
          about: 'ABOUT OKEN',
        },
      },
    },
  },
} satisfies {
  'en-US': OmitDeep<
    import('./pt.oken.ts').OkenIntlShape,
    'translations.forCreators' | 'translations.home.forCreators'
  >
}

export default merge(messagesEn, messages)
