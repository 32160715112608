import CVMFooter from 'openspace/components/CVMFooter/CVMFooter.tsx'
import DefaultLogic from './Footer.logic.tsx'

export default function OkenFooter() {
  return (
    <>
      <DefaultLogic />
      <CVMFooter />
    </>
  )
}
