import { OmitDeep } from 'type-fest'
import messagesES from './es.ts'
import { merge } from 'lodash'

const messages = {
  'es-ES': {
    translations: {
      generic: {
        brandMotto: 'Esto es raro. Este es Oken.',
      },
      offerDetails: {
        goalReached_0: 'Sin cuotas reservadas por el momento',
      },
      home: {
        banner: {
          title: 'INVIERTA CON OKEN',
          subtitle:
            'Mercado de activos tokenizados personalizado para su comunidad',
          v2: {
            brand: 'oken',
          },
        },
        sections: {
          gallery: {
            heading: 'Conoce a los emisores de tokens',
            title: 'Emisores de tokens',
            action: {
              seeAll: 'Ver todos los emisores',
            },
          },
          create: {
            title: '',
          },
          brand: {
            description: `Compensamos todas las emisiones de carbono de la plataforma y los activos negociados.

Oken también opera en blockchains que son eficientes en sus emisiones de gases de efecto invernadero.`,
          }
        },
      },
      allGalleries: {
        title: 'Emisores de Tokens',
        sections: {
          highlight: {
            title: 'Emisores de tokens destacados',
          },
          createYourOwn: {
            title: '',
          },
        },
      },
      galleryDetails: {
        noMarket: 'Artículos del emisor',
        noAvailable:
          '¡Mantente atento!\nPronto tendremos ofertas de este emisor',
      },
      topbar: {
        menus: {
          galleries: 'EMISORES',
          about: "ACERCA DE OKEN"
        },
      },
    },
  },
} satisfies {
  'es-ES': OmitDeep<
    import('./pt.oken.ts').OkenIntlShape,
    'translations.forCreators' | 'translations.home.forCreators'
  >
}

export default merge(messagesES, messages)
