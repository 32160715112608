import React from 'react'
import Styles from './CVMFooter.module.scss'
import Text from '../Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import { ReactComponent as CVMLogo } from 'openspace/assets/svg/cvm88.svg'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Container from '../Container/Container'
import { CVM_EMAIL } from 'core/constants'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { EscapedTranslation } from 'translate/i18n'

/**
 * A footer with CVM disclaimer
 **/
export default function CVMFooter() {
  const { tenant } = useTenant()
  return (
    <footer className={`${Styles.footer} light`}>
      <Container className={Styles.root}>
        <Text
          type={FigmaTypo.P12}
          format={{
            company: tenant?.displayName!,
            cvmMail: CVM_EMAIL,
          }}>
          <EscapedTranslation code='authenticated.checkout.dropDetails.cvm.footer' />
        </Text>
        <Spacing size='32' />
        <CVMLogo />
      </Container>
    </footer>
  )
}
