import React from 'react'
import View from './Intro.view'
import { IntroProps } from './Intro.types'
import { useUser } from 'core/logic/user'
import { useHistory } from 'react-router'
import { TO_MAINPAGE } from 'core/modules/router'
import { useCompletedPurchases } from 'core/logic/purchase/purchase.hook'

export default function IntroLogic(props: IntroProps) {
  const { profile, logout } = useUser()
  const history = useHistory()
  const purchases = useCompletedPurchases()
  return (
    <View
      user={profile}
      {...props}
      onGoToStore={() => history.push(TO_MAINPAGE())}
      onSetupWallet={() => {}}
      onSeePurchaseHistory={() => {}}
      onLogout={() => logout()}
      purchaseCount={purchases}
    />
  )
}
