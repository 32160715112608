import darkThemeFactory from './mode/dark'
import lightThemeFactory from './mode/light'

export default lightThemeFactory('#C0A7FF', '#654F9C', '#3C3466', {
  primary: 'Open Sans',
  secondary: 'Inter',
})
export const defaultMode = 'light'
export const alternativeMode = darkThemeFactory(
  '#C0A7FF',
  '#654F9C',
  '#3C3466',
  {
    primary: 'Open Sans',
    secondary: 'Inter',
  }
)
