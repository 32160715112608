import { Fragment, lazy, RefObject, Suspense } from 'react'
import Container from 'openspace/components/Container'
import { HomeViewProps } from './Home.types'
import Styles from './Home.module.scss'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { tE, tO } from 'translate/i18n'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import Section from 'openspace/components/Section'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import LazyBadge from 'openspace/components/LazyBadge'
import Footer from 'components/Footer'
import { HomeTestIds } from './Home.e2e'
import GalleryCard from 'openspace/components/GalleryCard'
import { OfferType } from 'core/logic/drop/drop.types'
import { Paginable } from '@onepercentio/one-ui/dist/hooks/usePagination'
import DropItem from 'openspace/components/DropItem'
import { DropItemViewMode } from 'openspace/components/DropItem/DropItem.types'
import { IPOBanner } from 'pages/Unauthenticated/Drops/Details/Models/IPO/IPO.logic'
import { useFeatures } from 'context/Feature'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing'
import { default as ChallengeSection } from 'features/Challenge/ChallengeSection'
import GalleryCardVariables from 'openspace/components/GalleryCard/GalleryCard.module.scss'

const LogoType = lazy(() => import('../../../components/Background/BG'))

export function GalleriesSection({ onSeeAll }: { onSeeAll: () => void }) {
  const { galleries } = useGalleries()
  const galleriesBaseWidth =
    galleries?.[0].type === 'cvm88'
      ? Number(GalleryCardVariables.baseWidthcvm88) + 16
      : Number(GalleryCardVariables.baseWidthdefault) + 26
  return (
    <>
      <Section
        data-testid={HomeTestIds.GallerySection}
        title={tE('home.sections.gallery.title')}
        seeAll={{
          onClick: onSeeAll,
          label: tO('home.sections.gallery.action.seeAll'),
        }}
        src={galleries || []}
        jsx={{
          empty: null,
          loading: null,
          baseWidth: galleriesBaseWidth,
          factory: (b) => (
            <GalleryCard
              gallery={b}
              shouldExpandOnMount={false}
              mode={b.highlight ? 'highlight' : 'default'}
            />
          ),
        }}
      />
    </>
  )
}

export function LatestItensSection({
  drops,
  containerRef,
}: {
  drops: Paginable<OfferType>
  containerRef: RefObject<HTMLDivElement>
}) {
  return (
    <Section
      ref={containerRef}
      data-testid={HomeTestIds.ItemsSection}
      title={tO('home.sections.latest.title')}
      src={drops}
      jsx={{
        empty: null,
        loading: null,
        baseWidth: 302 + 26,
        factory: (asset) => (
          <DropItem
            mode={DropItemViewMode.EXPANDED}
            drop={asset}
            key={asset.id}
            availability={'open'}
          />
        ),
      }}
    />
  )
}

export function Partnership() {
  return (
    <Container className={Styles.partnership}>
      <div>
        <Suspense fallback={<svg />}>
          <LogoType />
        </Suspense>
        <LazyBadge className={Styles.badge} />
      </div>
      <div>
        <Text type={FigmaTypo.H2}>{tO('home.sections.partnership.title')}</Text>
        <Text type={FigmaTypo.P16}>
          {tO('home.sections.partnership.description')}
        </Text>
      </div>
    </Container>
  )
}

export function HighlightsSection({
  drops,
}: {
  drops: Paginable<OfferType>
}) {
  return (
    <Section
      data-testid={HomeTestIds.ItemsSection}
      title={tO('home.sections.highlights.title')}
      src={drops}
      jsx={{
        empty: null,
        loading: null,
        baseWidth: 302 + 26,
        factory: (asset) => {
          switch (asset.type) {
            case 'cvm88':
              return <IPOBanner offer={asset} />
            default:
              throw new Error(
                `There is no highlight banner defined for offers of type ${asset.type}`
              )
          }
        },
      }}
    />
  )
}

export { ChallengeSection }

/**
 * Where the user checks out the galeries
 **/
export default function HomeView(props: HomeViewProps) {
  const { hasGalleries } = useFeatures()

  return (
    <>
      <Spacing size='32' />
      <ChallengeSection />
      <HighlightsSection drops={props.highlightsPaginable} />
      {hasGalleries && (
        <GalleriesSection
          key={'g'}
          onSeeAll={() => props.onSeeAll('gallery')}
        />
      )}
      <LatestItensSection
        key={'a'}
        drops={props.dropsPaginable}
        containerRef={props.dropsContainerRef}
      />
      <Partnership />
      <Footer />
    </>
  )
}
