import openspaceMessages from 'openspace/translate/languages/es'
import merge from 'lodash/merge'
import { OverwrittenOkenTranslations } from './pt.proxy.oken'
import { messages as baseMessages } from './rarum.es'

const okenMessages = {
  'es-ES': {
    translations: {
      features: {
        challenge: {
          claim: {
            confirmation: {
              deplete: `El item {{itemName}} ya no estará disponible en su cartera, ya que todas las unidades están siendo utilizadas para el reclamo.`,
              warning: `Los artículos seleccionados para el reclamo se transformarán en un nuevo item y la cantidad disponible se reducirá en "Mi cartera".`,
            },
          },
        },
      },
      walletRequiredControl: {
        transferWallet: {
          fromEmailLink: {
            actions: {
              understoodDanger:
                'Soy consciente de los riesgos y beneficios de usar una cartera externa y que OKEN ya no tendrá acceso a mis NFTs después de que se complete la migración.',
            },
          },
        },
        migrationRequired: {
          description:
            'Para proceder con esta operación, primero necesitas conectar los elementos de tu cartera OKEN a tu cartera Metamask.',
        },
      },
      marketplace: {
        offerCreation: {
          creation: {
            error: {
              description:
                'Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io',
            },
          },
          edition: {
            error: {
              description:
                'Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io',
            },
          },
          removal: {
            error: {
              description:
                'Por favor, inténtalo de nuevo. Si el error persiste, ayúdanos a mejorar, contáctanos en contact@onepercent.io',
            },
          },
        },
        buyOffer: {
          whatIsRarum: '¿Qué es OKEN?',
        },
        banner: {
          header: {
            description:
              'Descubre los artículos a la venta y todas las tarjetas que pueden formar parte de tu colleción',
          },
          faq: {
            title:
              '<b>Ver cómo vender</b> los elementos disponibles en su colleción',
          },
        },
      },
      highlights: {
        marketplace: {
          description:
            'Aumenta tu cartera, vendé tarjetas duplicadas y completa tu cartera.',
        },
      },
      unauthenticated: {
        socialSign: {
          rarumInscription:
            'Al inscribirse en OKEN, reconoce que ha leído y acepta todos los',
        },
        drops: {
          details: {
            info: {
              notAvailable:
                'No hay más unidades disponibles de este artículo. Seleccione otro para coleccionar más artículos con OKEN.',
            },
          },
        },
        connect: {
          createAccount: 'Cree su cuenta en OKEN',
          login: 'Inicie sesión en OKEN',
        },
        faqview: {
          faq: {
            3: `Las cartas se ofrecen de forma aleatoria, a través de paquetes. Elige uno de los paquetes de las distintas colecciones disponibles. Cada paquete contiene una cierta cantidad de cartas aleatorias de ese conjunto. Los paquetes se distinguen por su rareza y deseo. Cuanto más raro, mayor será el deseo de obtención y menor la disponibilidad.`,
            4: `Después de seleccionar un paquete, serás llevado a la pantalla de compra. Elige un método de pago e introduce los datos solicitados. Una vez aprobado el pago, nuestro sistema entrega los NFT a tu cartera de forma aleatoria y según la rareza elegida: hero, premium y legend.`,
            5: `Listo, recibirás el paquete en tu cartera. Haz clic en "abrir paquete" y revela tus cartas. Ahora es posible verificar su propiedad en blockchain, con transparencia e inmutabilidad. Las cartas se almacenarán en tu cartera y pronto podrás intercambiarlas con otros coleccionistas.`,
            accordion: {
              title: {
                '1': '¿Qué es blockchain?',
                '2': '¿Qué son los tokens?',
                '3': '¿Qué son las carteras?',
                '4': '¿Qué es RWA (Real World-Assets)?',
                '5': '¿En qué blockchain se generan mis tokens?',
                '6': '¿Qué métodos de pago se aceptan en la plataforma?',
                '7': '¿Necesito conocer cripto y/o blockchain para operar en Oken.Finance?',
                '8': '¿Qué es una plataforma whitelabel?',
                '9': '¿Qué son los valores mobiliarios?',
                '10': '¿Qué son los activos tokenizados?',
                '11': '¿Están todas las ofertas validadas por la CVM?',
                '12': '¿Qué es la plataforma electrónica de inversión participativa?',
                '13': '¿Cómo invertir a través de la plataforma?',
                '14': '¿Qué sucede después de realizar una inversión a través de la plataforma?',
                '15': '¿Cómo se determina el éxito de la inversión?',
                '16': '¿Cuál es la forma de confirmación de la inversión?',
                '17': '¿El inversor tiene derecho a desistir de la inversión?',
                '18': '¿Quién puede invertir en la plataforma electrónica de inversión?',
                last: '¿Puedo invertir más de R$ 20,000 en activos tokenizados?',
              },
              content: {
                '1': 'Un blockchain es esencialmente un libro digital de transacciones que se duplica y distribuye a través de la red de sistemas informáticos en Internet. Blockchain es un término que se ha utilizado mucho cuando se refiere a una nueva infraestructura tecnológica web3 (una nueva fase de Internet) cuyas principales características son: descentralización, seguridad, transparencia y desintermediación. Esta tecnología permitió la creación de Bitcoin en 2008, y ahora se usa como el elemento clave en la economía tokenizada, que es la digitalización total de cualquier activo y aspecto de nuestra economía.',
                '2': 'Los tokens son el componente esencial utilizado en aplicaciones en blockchain. Los tokens se pueden usar para representar cualquier cosa, por ejemplo, pueden representar una reserva de valor, un activo digital o del mundo real, como propiedad, arte, bienes en una cadena de suministro y títulos. No hay límites para lo que puede ser representado como un token.',
                '3': 'Una billetera blockchain es una billetera digital que los usuarios pueden usar para almacenar y gestionar activos digitales y criptomonedas (tokens). Una billetera blockchain permite a los usuarios transferir y gestionar sus activos digitales y monedas digitales; también es la forma en que los usuarios pueden convertir sus activos digitales de nuevo en su moneda local.',
                '4': 'Los activos del mundo real, también llamados RWAs, son tokens que representan ciertos activos físicos o no, que pueden ser bienes, objetos, valores u otros. Al igual que otros tokens, funcionan en el blockchain a través de contratos inteligentes; un token puede equivaler a un objeto entero o incluso a una parte/fracción.',
                '5': 'Nuestra plataforma es compatible con todas las redes compatibles con el estándar Ethereum Virtual Machine (EVM), que incluye redes como Ethereum, Polygon, Celo, Polkadot y muchas otras.',
                '6': 'Nuestra plataforma acepta los métodos de pago comúnmente utilizados en el mercado, incluidos PIX, Tarjeta de Crédito y Cripto (BRLA, USDC).',
                '7': 'Con Oken Finance, el acceso al mundo web3 es simple. Nuestras soluciones están diseñadas para gestionar toda la complejidad del blockchain para usted, sin necesidad de entender cómo funciona o sin una billetera externa para gestionar sus activos digitales. Sin embargo, los usuarios más avanzados pueden conectar sus billeteras como Metamask y usar sus criptomonedas si lo desean.',
                '8': 'Whitelabel es una plataforma que su empresa puede personalizar para lanzar su propio mercado en blockchain sin ningún esfuerzo de desarrollo. Es la forma más fácil de adoptar la estrategia web3 para su empresa y lanzar sus propios activos digitales para explorar las oportunidades del mercado.',
                '9': 'Los valores mobiliarios representados por los tokens pueden ser activos subyacentes como instrumentos de inversión en participación societaria en Startups (Equity), así como títulos de securitización, representativos, vinculados o respaldados por derechos crediticios o títulos de deuda emitidos por Startups, conocidos como “tokens de recibibles” o “tokens de renta fija”.',
                '10': 'La tokenización es el proceso de representar los activos reales en forma de tokens digitales y plataformas electrónicas que utilizan blockchain. Al igual que un título de propiedad en una oficina de registro de propiedades representa la información sobre una propiedad determinada, un token en blockchain representa un activo determinado, que puede ser un % de equity, un derecho crediticio, un precatorio, un título de renta fija, entre muchas otras posibilidades.',
                '11': 'Las plataformas electrónicas de inversión licenciadas por CVM 88 operan dentro de los límites de la regulación, y dentro de estos límites, no es necesario el registro y aprobación de cada oferta pública, siempre y cuando se respeten los límites y reglas de CVM88.',
                '12': 'La plataforma realiza la actividad de plataforma electrónica de inversión participativa (Crowdfunding), autorizada por la Comisión de Valores Mobiliarios (CVM) para realizar ofertas públicas y distribución de tokens respaldados por valores mobiliarios emitidos por Empresas de Pequeño Porte (EPPs) (es decir, Startups), automáticamente exentas de registro en la CVM, de acuerdo con la Resolución CVM 88.',
                '13': 'Los inversores acceden a la plataforma e inician sesión/se identifican a través de su inicio de sesión social o correo electrónico. La plataforma proporciona información sobre ofertas públicas activas, detallando información sobre la captación, el perfil de las ofertas, información sobre la inversión y otra información específica de la oferta. Si el inversor aún no ha completado el registro completo y el perfil, será necesario completar toda la información antes de continuar con la inversión para llevar a cabo el proceso de registro (KYC). Después de reservar la inversión, el inversor recibe los detalles de una cuenta de pago creada a su nombre para transferir el monto reservado.',
                '14': 'El monto permanece en esta cuenta hasta que se complete la oferta, momento en el cual se transfiere a la cuenta de la empresa emisora de los valores mobiliarios o se devuelve a la cuenta del inversor, que puede transferirlo a otra cuenta bancaria a su nombre en cualquier momento. Si la oferta no se completa por no alcanzar el valor mínimo necesario, que siempre es ⅔ (dos tercios) del objetivo de captación, los montos invertidos y pagados se devolverán a los inversores, en la misma cuenta corriente/pago de origen a nombre del inversor.',
                '15': 'Para que una oferta tenga éxito, la empresa emisora de los valores mobiliarios debe alcanzar, dentro del límite de 180 días de la oferta, el valor mínimo de ⅔ (dos tercios) del objetivo de captación en inversiones confirmadas (“Oferta Parcial”). Incluso después de alcanzar los ⅔ (dos tercios) del valor de la captación, la oferta puede continuar activa para intentar llegar al objetivo total de captación (“Oferta Total”), respetando el límite de 180 días desde el inicio de la oferta.',
                '16': 'La inversión se confirma mediante la firma del Término de Adhesión al contrato de inversión, o contrato de préstamo, o instrumento de deuda, lo que sea legalmente adecuado para la oferta que se presenta, generado en el momento de la reserva, o mediante la transferencia del monto reservado a la cuenta de pago informada por la Plataforma.',
                '17': 'El inversor tiene hasta 5 (cinco) días para desistir de la inversión, sin incurrir en ninguna multa o penalidad, después de la confirmación de la inversión y el pago de los montos. Si los fondos ya se han transferido a la cuenta de pago creada a nombre del inversor, la Plataforma organizará la devolución de los fondos al inversor, en la misma cuenta corriente utilizada para realizar el pago de la inversión, a su nombre, dentro de los 5 (cinco) días hábiles.',
                '18': 'Personas físicas o jurídicas pueden invertir hasta R$ 20,000.00 (veinte mil reales) a través de crowdfunding por año, considerando el total invertido a través de otras plataformas. Para cada inversión, el inversor debe firmar una declaración (según el Anexo C de la Resolución CVM 88) en la que afirma que el monto invertido en la Plataforma sumado a otras inversiones de la misma naturaleza no supera el monto mencionado.',
                last: 'Sí, siempre que cumpla con estos criterios: a) Poseer patrimonio financiero o ingresos brutos anuales superiores a R$ 200,000.00 (doscientos mil reales) y confirmar que el monto invertido ese año, considerando todas las plataformas, no superará el 10% de su patrimonio financiero o ingresos brutos anuales, lo que sea mayor; b) Ser un inversor calificado - poseer inversiones financieras superiores a R$ 1,000,000.00 (un millón de reales) y tener conocimiento sobre el mercado financiero. En este caso, no hay límite anual de inversión y el inversor debe firmar la declaración contenida en el Anexo A de la Resolución CVM 88.',
              },
            },
          },
        },
        claim: {
          errors: {
            alreadyClaimed: {
              actions: {
                showItem: 'Mostrar en la cartera',
              },
            },
          },
        },
      },
      authenticated: {
        myCollection: {
          myCollection: 'MI CARTERA',
          accessDetails:
            'Accede y consulta los detalles de los artículos de tu cartera.',
          collectionItemDetails: {
            noBalance: {
              action: {
                backToMyCollection: 'Ver mi cartera',
              },
            },
          },
          emptyCollection: {
            empty: 'Aún no tienes ningún artículo en tu cartera.',
          },
        },
        openingDrop: {
          message3:
            'Puedes ver todos los artículos de tu cartera directamente en la blockchain. Si tiene dudas sobre dónde obtener los datos, visite nuestras FAQ o envíanos un email',
          modals: {
            errorModal: {
              myCollection: 'Mi Cartera',
              tryAgain:
                'Vuelva a intentarlo más tarde o comuníquese con OKEN a través de info@oken.finance.',
            },
            exitModal: {
              confirmToExitMessage:
                'Una vez que se complete el proceso de carga, los elementos del paquete estarán disponibles en Mi Cartera. ',
            },
            timeOutModal: {
              myCollection: 'VOLVER A MI CARTERA',
              waitingMessage1:
                'Tenga la seguridad de que sus artículos estarán disponibles en su cartera en breve.',
              waitingMessage2:
                '¿Por qué puede producirse este retraso? El proceso de apertura se ejecuta en la blockchain y puede variar en función del volumen de transacciones en la red en ese momento. Por favor, espere unos minutos o vuelva pronto a su cartera.',
            },
          },
          opened: {
            myCollection: 'Mi Cartera',
          },
        },
        pendingPayment: {
          myCollection: 'Mi Cartera',
        },
        transfer: {
          confirmation: {
            description: {
              part2:
                'Los tokens enviados a la dirección se debitarán de su cartera una vez que se confirme la transacción en la cadena de bloques.',
            },
          },

          request: {
            description:
              'Esta opción le permite enviar su NFT a una cartera externa. Esto hará que su artículo salga de OKEN y sea totalmente controlado por esta cartera. Complete los campos a continuación o aprenda más en el siguiente enlace.',
          },
          pendingExternalTransfer: {
            description:
              'Ha conectado su cartera, pero aún no ha transferido sus saldos de su cuenta. Para hacerlo, inicie el proceso de migración haciendo clic en el botón a continuación. Recibirá un enlace en su correo electrónico para confirmar la migración.\nTenga en cuenta que todos los saldos en su cuenta OKEN serán transferidos, así que asegúrese de tener acceso a su cartera conectada. Una vez transferidos, no podremos moverlos en su nombre.',
          },
        },
        userProfile: {
          communication: {
            cancel: {
              description:
                'Cancele su cuenta y elimine su información de la plataforma OKEN',
            },
          },
        },
      },
      components: {
        footer: {
          view: {
            sustainableNFT: 'Inversión en activos tokenizados',
          },
          data: {
            collection: 'Cartera',
          },
        },
        topBar: {
          myCollection: 'Mi Cartera',
        },
        rarumHeader: {
          whatIsRarum: '¿Qué es OKEN?',
        },
      },
      core: {
        constants: {
          rarumDescription: [
            'OKEN Finance es una plataforma de inversión regulada autorizada por la CVM bajo la regulación 88. Nuestra plataforma utiliza tokens para representar sus activos, combinando los beneficios de Web3 con el mercado de capitales.',
            'Cada artículo en la plataforma tiene su consumo de carbono (emisiones de dióxido de carbono, que son altamente contaminantes, generadas durante la producción y entrega de los artículos) automáticamente compensado utilizando Carbon-Pegged Crypto Tokens.',
            'Nuestra plataforma también se responsabiliza por la seguridad de sus datos y activos. Por esta razón, utilizamos tecnología blockchain para garantizar la validez de los artículos comercializados en la plataforma. Cada artículo generado corresponde a un registro único, que actúa como un certificado de origen con una firma criptográfica del creador de cada artículo. De esta manera, puede estar seguro de que utilizamos la tecnología más moderna para certificar que los datos son válidos y las transacciones son seguras.',
            'Si desea saber más sobre OKEN, puede contactarnos en info@oken.finance',
          ],
          dataDeletionInstructions: [
            'Si desea eliminar sus datos de nuestros servidores, puede eliminarlos siguiendo las instrucciones a continuación:',
            '1. Vaya a "Configuración y privacidad" > "Configuración";',
            '2. Luego vaya a "Aplicaciones y sitios web";',
            '3. Elimine "OKEN" de sus aplicaciones;',
            '4. Envíe un correo electrónico con el asunto "ELIMINAR MIS DATOS" a contact@oken.finance  desde el correo electrónico con el que creó su cuenta. Sus datos serán eliminados de nuestros servidores dentro de 1 día hábil.',
          ],
          shareTitle: 'OKEN - Activos digitales',
          externalEnvironmentAlert:
            'Será redirigido a un entorno externo, el proveedor de pagos asociado de OKEN. Siga las instrucciones del método de pago seleccionado en la próxima pantalla.',
        },
      },
    },
  },
} satisfies {
  'es-ES': OverwrittenOkenTranslations
}

const messages = merge(baseMessages, okenMessages, openspaceMessages)

export { messages }
