import { PropsWithChildren } from 'react'

export type UserProfileProps = {}
export type UserProfileViewProps = PropsWithChildren<{}>

export enum USER_PROFILE_STEP {
  PERSONAL_DATA,
  DOCUMENTS,
  INVESTOR_PROFILE,
  COMMUNICATION,
  INTRO,
}
