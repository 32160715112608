import { useLocalPagination } from '@onepercentio/one-ui/dist/hooks/usePagination'
import { ERC1155ABI, ERC20ABI } from 'core/constants'
import { ERC721ABI } from 'core/constants/ERC721'
import { currencyFormatterFactory } from 'core/helpers/formatter'
import { AssetType } from 'core/logic/asset/asset.types'
import { ContractType } from 'core/logic/contract/types/types'
import { useDrops } from 'core/logic/drop/drop.hook'
import { OfferType } from 'core/logic/drop/drop.types'
import { useGalleries } from 'core/logic/gallery/gallery.hook'
import { GalleryType } from 'core/logic/gallery/gallery.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import {
  DeprecatedTenantType,
  TenantType,
} from 'core/logic/tenant/tenant.types'
import useWeb3Provider from 'hooks/useWeb3Provider'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { i18n } from 'translate/i18n'

export type GalleryContextShape = {
  galleries: GalleryType[] | null
  filter: {
    categories: string[]
    onSelectCategory: (cat?: string) => void
    selectedCategory?: string
    filteredGalleries: GalleryType[]
  }
  contracts: {
    [address: string]: Contract.ERC1155 | Contract.ERC721 | Contract.IERC20
  }
}
export const GalleryContext = createContext<GalleryContextShape>(null as any)

function useCategoryFilter(
  galleries: GalleryType[]
): GalleryContextShape['filter'] {
  const [selectedCategory, setSelectedCategory] = useState<string>()
  const categories = useMemo(() => {
    return Array.from(
      new Set(galleries.map((g) => g.category).filter((a) => !!a))
    )
  }, [galleries])
  const filteredGalleries = useMemo(() => {
    return galleries.filter((a) => {
      const isFilteredByCategory = selectedCategory
        ? a.category === selectedCategory
        : true
      return isFilteredByCategory
    })
  }, [galleries, selectedCategory])
  return {
    categories,
    selectedCategory,
    onSelectCategory: setSelectedCategory,
    filteredGalleries,
  }
}
export function GalleryProvider({ children }: PropsWithChildren<{}>) {
  const { galleries } = useGalleries()
  const { tenant } = useTenant()
  const filter = useCategoryFilter(galleries || [])

  const { current: contractInstances } = useRef<
    GalleryContextShape['contracts']
  >({})
  const web3 = useWeb3Provider()
  const contracts = useMemo(() => {
    if (!web3 || !galleries || !tenant) return contractInstances
    const galleryContracts = galleries.map((g) => ({
      smartContract: g.smartContract,
    }))
    const deprecatedTenant = tenant as TenantType | DeprecatedTenantType
    if ('smartContract' in deprecatedTenant)
      galleryContracts.push({ smartContract: deprecatedTenant.smartContract })

    for (let gallery of galleryContracts) {
      const contractAddress = gallery.smartContract!.address
      if (!contractInstances[contractAddress]) {
        contractInstances[contractAddress] = (() => {
          switch (gallery.smartContract!.type) {
            case ContractType.RarumNFT:
              return new web3.eth.Contract(
                ERC1155ABI,
                contractAddress
              ) as unknown as Contract.ERC1155
            case ContractType.Loyalty:
              return new web3.eth.Contract(
                ERC721ABI,
                contractAddress
              ) as unknown as Contract.ERC721
            case ContractType.Fungible:
              return new web3.eth.Contract(
                ERC20ABI,
                contractAddress
              ) as unknown as Contract.IERC20
            default:
              throw new Error(
                `Unexpected contract type ${gallery.smartContract!.type}`
              )
          }
        })()
      }
    }
    return contractInstances
  }, [galleries, web3, tenant])
  return (
    <GalleryContext.Provider
      value={{
        galleries,
        filter,
        contracts,
      }}>
      {children}
    </GalleryContext.Provider>
  )
}

export function useGalleryDrops(
  galleryId: GalleryType['id'],
  pageSize: number
) {
  const { active, inactive } = useDrops()
  const offers = useMemo(
    () => active?.filter((offer) => offer.galleryId === galleryId),
    [active, galleryId]
  )

  const pagination = useLocalPagination(offers as OfferType[])
  useEffect(() => {
    pagination.getPage(0, pageSize * 2)
  }, [pageSize, active])

  const inactiveAssets = useMemo(
    () => inactive?.filter((offer) => offer.galleryId === galleryId),
    [inactive, galleryId]
  )
  const inactivePagination = useLocalPagination(inactiveAssets as OfferType[])
  useEffect(() => {
    inactivePagination.getPage(0, pageSize * 2)
  }, [pageSize, inactive])

  const availableGroups = useMemo(() => {
    if (!offers) return {}
    return offers?.reduce(
      (map, offer) => {
        if (offer.groups)
          for (let group of offer.groups) {
            if (!map[group]) map[group] = []
            map[group].push(offer as OfferType)
          }
        return map
      },
      {} as {
        [groupId: NonNullable<OfferType['groups']>[number]]: OfferType[]
      }
    )
  }, [offers])

  return {
    active: pagination,
    inactive: inactivePagination,
    groups: availableGroups,
  }
}

export function useAllGalleryDrops(pageSize: number) {
  const { active } = useDrops()
  const assets = useMemo(
    () => active?.filter((offer) => !!offer.galleryId) || [],
    [active]
  )
  const pagination = useLocalPagination(assets as OfferType[])

  useEffect(() => {
    if (assets.length) pagination.getPage(0, pageSize * 2)
  }, [pageSize, assets])

  return pagination
}

export function useAllHighlightDrops(pageSize: number) {
  const { src } = useAllGalleryDrops(pageSize)
  const pagination = useLocalPagination(src?.filter((o) => o.highlight))

  useEffect(() => {
    pagination.getPage(0, pageSize * 2)
  }, [src])

  return pagination
}

export function useGalleryContext() {
  return useContext(GalleryContext)
}

export function useGallery(galleryId: string) {
  return useGalleryContext().galleries?.find((g) => g.id === galleryId)
}

export function useGalleriesForAssets(assets: AssetType[]) {
  const { galleries } = useGalleryContext()
  const galleryIds = useMemo(
    () => Array.from(new Set(assets.map((asset) => asset.galleryId))),
    [assets]
  )
  const galleriesForAssets = useMemo(
    () => galleries?.filter((g) => galleryIds.includes(g.id)),
    [galleries, galleryIds]
  )
  return {
    loadedAllGalleries: galleryIds.length === galleriesForAssets?.length,
    galleries: galleriesForAssets,
  }
}

export function useContractForGalleries(
  galleries: (GalleryType | undefined)[]
) {
  const { contracts } = useContext(GalleryContext)
  const galleriesWithContract = useMemo<
    (GalleryType & {
      contract: Contract.ERC1155 | Contract.ERC721 | Contract.IERC20
    })[]
  >(() => {
    return galleries
      .map((gallery) => {
        if (gallery)
          return {
            ...gallery,
            contract: contracts[gallery.smartContract!.address],
          }
      })
      .filter((item): item is NonNullable<typeof item> => !!item)
  }, [contracts, galleries])

  return galleriesWithContract
}

export function useContractForGallery(gallery: GalleryType | undefined) {
  return useContractForGalleries([gallery])[0]
}

export function useContracts() {
  return useContext(GalleryContext).contracts
}

export function useEmitterGalleryInfo(gallery: GalleryType) {
  const { active, inactive } = useDrops()
  const dropsToConsider = useMemo(() => {
    if (!active || !inactive) return undefined
    return active.filter((drop) => drop.galleryId === gallery.id)
  }, [gallery, active, inactive])

  const volume = useMemo(() => {
    if (!dropsToConsider) return undefined
    const volumeForEachOffer = dropsToConsider!.map(
      (drop) => drop.supply * drop.prices!.brl!
    )
    return volumeForEachOffer.reduce((s, v) => s + v, 0)
  }, [dropsToConsider])

  return {
    formatter: currencyFormatterFactory('brl'),
    totalVolume: volume,
    projects: dropsToConsider?.map((drop) => ({
      img: drop.media.icon,
      name: (drop as OfferType)[i18n.language].title,
    })),
  }
}
