import { AssetWithBalance, Sale } from 'core/logic/asset/asset.types'
import MarketplaceInformationView from './MarketplaceInformation.view'
import { useMarketplace } from 'context/Marketplace'
import { useHistory } from 'react-router-dom'
import { useEffect, useMemo, useState } from 'react'
import ROUTES, { TO_MARKETPLACE_OFFER_DETAILS } from 'core/modules/router'
import { useUser } from 'core/logic/user'

export default function MarketplaceInformationLogic({
  asset,
}: {
  asset: AssetWithBalance
}) {
  const { currencies, sales } = useMarketplace()
  const [selectedCurr, setSelected] = useState<CryptoCurrencySymbol>()
  const salesForItem = useMemo(
    () => sales.items?.filter((a) => a.assetId === asset.id),
    [sales.items]
  )
  const history = useHistory()
  const { profile } = useUser()

  useEffect(() => {
    if (currencies) setSelected(currencies[0])
  }, [currencies])

  useEffect(() => {
    if (salesForItem === undefined) {
      sales.getAll(asset.id)
    }
  }, [salesForItem])

  const filteredOffers = useMemo(() => {
    return salesForItem
      ?.reduce((unduplicatedSales, sale) => {
        if (
          !unduplicatedSales.some(
            (saleDup) =>
              sale.offerId === saleDup.offerId &&
              sale.currency === saleDup.currency
          )
        )
          return [...unduplicatedSales, sale]
        return unduplicatedSales
      }, [] as Sale[])
      ?.filter((s) => s.currency === selectedCurr)
  }, [salesForItem, selectedCurr])

  const userOffers = useMemo(() => {
    return filteredOffers?.filter((s) => s.wallet === profile?.wallet) || []
  }, [profile, filteredOffers])

  return (
    <MarketplaceInformationView
      asset={asset}
      currencies={currencies}
      selectedCurrency={selectedCurr}
      availableItens={(filteredOffers?.length || 0) - userOffers.length}
      onBuyOffer={(offerId) => {
        history.push(
          ROUTES.authenticated.marketplaceOfferBuy
            .replace(':assetId', asset.id)
            .replace(':offerId', offerId)
        )
      }}
      onManageOffer={(assetId, offerId) => {
        history.push(TO_MARKETPLACE_OFFER_DETAILS(assetId, offerId))
      }}
      onCurrencySelected={(c) => setSelected(c)}
      offers={filteredOffers}
      userOffers={userOffers}
      loading={salesForItem === undefined}
    />
  )
}
