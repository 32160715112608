import openspaceMessages from 'openspace/translate/languages/en'
import merge from 'lodash/merge'
import { OverwrittenOkenTranslations } from './pt.proxy.oken'
import { messages as baseMessages } from './rarum.en'

const okenMessages = {
  'en-US': {
    translations: {
      features: {
        challenge: {
          claim: {
            confirmation: {
              deplete: `The item {{itemName}} will no longer be available in your wallet, as all units are being used for claim.`,
              warning: `The selected items will be transformed into a new item, and the available quantity will be reduced in "My wallet".`,
            },
          },
        },
      },
      walletRequiredControl: {
        transferWallet: {
          fromEmailLink: {
            actions: {
              understoodDanger:
                'I am aware of the risks and benefits of using an external wallet and that OKEN will no longer have access to my NFTs after the migration is completed.',
            },
          },
        },
        migrationRequired: {
          description:
            'To proceed with this operation, you first need to connect the items in your wallet to your wallet.',
        },
      },
      marketplace: {
        offerCreation: {
          creation: {
            error: {
              description:
                'Please try again. If the error persists, help us improve, contact us at contact@oken.finance',
            },
          },
          edition: {
            error: {
              description:
                'Please try again. If the error persists, help us improve, contact us at contact@oken.finance',
            },
          },
          removal: {
            error: {
              description:
                'Please try again. If the error persists, help us improve, contact us at contact@oken.finance',
            },
          },
        },
        buyOffer: {
          whatIsRarum: 'What is OKEN?',
        },
        banner: {
          header: {
            description:
              'Discover the items on sale and all the items that can be part of your Wallet',
          },
          faq: {
            title:
              '<b>Learn how to sell</b> the items available on your Wallet',
          },
        },
      },
      highlights: {
        marketplace: {
          description:
            'Increase your Digital Wallet, sell duplicate cards and complete your wallet.',
        },
      },
      unauthenticated: {
        socialSign: {
          rarumInscription:
            'By signing up for OKEN, you acknowledge that you have read and agree to all the',
        },
        drops: {
          details: {
            info: {
              notAvailable:
                'No more units of this item are available. Select another to collect more items with OKEN.',
            },
          },
        },
        connect: {
          createAccount: 'Create your OKEN account',
          login: 'Login to OKEN',
        },
        faqview: {
          faq: {
            3: `The next step is to choose one of the packages from the collections available. Please remember: the cards are offered randomly. Each package contains a certain number of random cards from that collection. The packages differ in rarity and how desirable the cards are. The higher the rarity level, the more desirable the item is, and there are a lower number of them available.
            `,
            4: `Now it’s time to pay for your package. After selecting the one, you will be directed to a payment screen. Choose the payment method and insert the requested data. As soon as the payment is approved, our system will deliver the NFTs to your wallet randomly and according to the rarity chosen: hero, premium and legend.`,
            5: `And that is it! The final step is to receive your package in your wallet. Click on “open package” and reveal your cards. And it is already possible to check your property in the blockchain, with transparency and immutability. The cards will be stored in your wallet here and, soon, you will be able to negotiate them with other collectors. Congrats!`,
            accordion: {
              title: {
                '1': 'What is blockchain?',
                '2': 'What are tokens?',
                '3': 'What are wallets?',
                '4': 'What is RWA (Real World-Assets)?',
                '5': 'On which blockchain are my tokens generated?',
                '6': 'What payment methods are accepted on the platform?',
                '7': 'Do I need to know crypto and/or blockchain to operate on Oken.Finance?',
                '8': 'What is a whitelabel platform?',
                '9': 'What are securities?',
                '10': 'What are tokenized assets?',
                '11': 'Are all offers validated by the CVM?',
                '12': 'What is the electronic participatory investment platform?',
                '13': 'How to invest through the platform?',
                '14': 'What happens after making an investment through the platform?',
                '15': 'How is the success of the investment determined?',
                '16': 'What is the form of investment confirmation?',
                '17': 'Does the investor have the right to withdraw from the investment?',
                '18': 'Who can invest in the electronic investment platform?',
                last: 'Can I invest more than R$ 20,000 in tokenized assets?',
              },
              content: {
                '1': 'A blockchain is essentially a digital ledger of transactions that is duplicated and distributed across the network of computer systems on the Internet. Blockchain is a term that has been widely used when referring to a new web3 technological infrastructure (a new phase of the internet) whose main characteristics are: decentralization, security, transparency, and disintermediation. This technology enabled the creation of Bitcoin in 2008, and it is now used as a key element in the tokenized economy, which is the complete digitization of any asset and aspect of our economy.',
                '2': 'Tokens are the essential component used in blockchain applications. Tokens can be used to represent anything, for example, they can represent a store of value, a digital or real-world asset, such as property, art, goods in a supply chain, and securities. There are no limits to what can be represented as a token.',
                '3': 'A blockchain wallet is a digital wallet that users can use to store and manage digital assets and cryptocurrencies (tokens). A blockchain wallet allows users to transfer and manage their digital assets and digital currencies; it is also how users can convert their digital assets back into their local currency.',
                '4': 'Real-world assets, also called RWAs, are tokens that represent certain physical or non-physical assets, which can be goods, objects, securities, or others. Like other tokens, they operate on the blockchain through smart contracts; a token can equate to an entire object or even a part/fraction.',
                '5': 'Our platform is compatible with all networks that support the Ethereum Virtual Machine (EVM) standard, which includes networks such as Ethereum, Polygon, Celo, Polkadot, and many others.',
                '6': 'Our platform accepts commonly used payment methods in the market, including PIX, Credit Card, and Crypto (BRLA, USDC).',
                '7': 'With Oken Finance, accessing the web3 world is simple. Our solutions are designed to manage all the complexity of the blockchain for you, without needing to understand how it works or without an external wallet to manage your digital assets. However, more advanced users can connect their wallets like Metamask and use their cryptocurrencies if they wish.',
                '8': 'Whitelabel is a platform that your company can customize to launch its own blockchain marketplace without any development effort. It is the easiest way to adopt the web3 strategy for your company and launch your own digital assets to explore market opportunities.',
                '9': 'Securities represented by tokens can be underlying assets such as equity investment instruments in Startups, as well as securitization securities, representative, linked, or backed by credit rights or debt securities issued by Startups, known as “receivables tokens” or “fixed income tokens”.',
                '10': 'Tokenization is the process of representing real assets in the form of digital tokens and electronic platforms that use blockchain. Just like a real estate title in a land registry office represents information about a certain property, a token on the blockchain represents a certain asset, which can be a percentage of equity, a credit right, a precatory, a fixed income security, among many other possibilities.',
                '11': 'Electronic investment platforms licensed by CVM 88 operate within the limits of the regulation, and within these limits, there is no need for registration and approval of each public offering, as long as the limits and rules of CVM88 are respected.',
                '12': 'The platform performs the activity of an electronic participatory investment platform (Crowdfunding), being authorized by the Securities and Exchange Commission (CVM) to carry out public offers and distribution of tokens backed by securities issued by Small Enterprises (EPPs) (i.e., Startups), automatically exempted from registration with the CVM, according to CVM Resolution 88.',
                '13': 'Investors access the platform and log in/identify themselves via their social login or email. The platform provides information about active public offers, detailing information about the fundraising, offer profile, investment information, and other specific offer information. If the investor has not yet completed the full registration and profile completion, it will be necessary to complete all the information before proceeding with the investment to conduct the registration process (KYC). After reserving the investment, the investor receives the details of a payment account created in their name to transfer the reserved amount.',
                '14': "The amount stays in this account until the offer is completed when it is transferred to the issuer's account of the securities or returned to the investor's account, which can transfer it to another bank account in their name at any time. If the offer is not completed by not reaching the minimum necessary value, which is always ⅔ (two-thirds) of the target fundraising amount, the invested and paid amounts will be returned to the investors, in the same current/payment account of origin in the investor's name.",
                '15': "For an offer to be successful, the issuer of the securities must reach, within the offer's 180-day limit, the minimum value of ⅔ (two-thirds) of the target fundraising amount in confirmed investments (“Partial Offer”). Even after reaching ⅔ (two-thirds) of the fundraising value, the offer can remain active to try to reach the total target fundraising amount (“Total Offer”), respecting the 180-day limit from the start of the offer.",
                '16': 'The investment is confirmed by signing the Term of Adhesion to the investment contract, or loan contract, or debt instrument, whichever is legally appropriate for the offer being presented, generated at the time of reservation, or by transferring the reserved amount to the payment account informed by the Platform.',
                '17': "The investor has up to 5 (five) days to withdraw from the investment, without incurring any fines or penalties, after the investment confirmation and payment of the amounts. If the funds have already been transferred to the payment account created in the investor's name, the Platform will arrange for the return of the funds to the investor, in the same current account used to make the investment payment, in their name, within 5 (five) business days.",
                '18': 'Individuals or legal entities can invest up to R$ 20,000.00 (twenty thousand reais) via crowdfunding per year, considering the total invested through any other platforms. For each investment, the investor must sign a declaration (as per Annex C of CVM Resolution 88) stating that the amount invested on the Platform, combined with other investments of the same nature, does not exceed the aforementioned amount.',
                last: 'Yes, as long as you meet these criteria: a) Have financial assets or gross annual income exceeding R$ 200,000.00 (two hundred thousand reais) and confirm that the amount invested that year, considering all platforms, will not exceed 10% of your financial assets or gross annual income, whichever is greater; b) Be a qualified investor - have financial investments exceeding R$ 1,000,000.00 (one million reais) and have knowledge about the financial market. In this case, there is no annual investment limit, and the investor must sign the declaration contained in Annex A of CVM Resolution 88.',
              },
            },
          },
        },
        claim: {
          errors: {
            alreadyClaimed: {
              actions: {
                showItem: 'Show in wallet',
              },
            },
          },
        },
      },
      authenticated: {
        userProfile: {
          communication: {
            cancel: {
              description:
                'Cancel your account and delete your information from the OKEN platform',
            },
          },
        },
        myCollection: {
          myCollection: 'MY WALLET',
          accessDetails:
            'Access and see the details of the items of your wallet.',
          collectionItemDetails: {
            noBalance: {
              action: {
                backToMyCollection: 'See my wallet',
              },
            },
          },
          emptyCollection: {
            empty: "You don't have any items in your wallet yet.",
          },
        },
        openingDrop: {
          message3:
            'You can view all the items in your wallet directly on the blockchain. If you have questions about where to get the data, please visit our FAQ or contact us',
          modals: {
            errorModal: {
              myCollection: 'My Wallet',
              tryAgain:
                'Please try again later, or contact OKEN through info@oken.finance.',
            },
            exitModal: {
              confirmToExitMessage:
                'After the upload process is complete, the items will be available in My Wallet. ',
            },
            timeOutModal: {
              myCollection: 'GO BACK TO MY WALLET',
              waitingMessage1:
                'Rest assured, your items will be available in your wallet soon.',
              waitingMessage2:
                'Why can this take so long? The opening process runs on the blockchain and may vary depending on the volume of transactions on the network at the moment. Please wait a few minutes or return to your wallet soon.',
            },
          },
          opened: {
            myCollection: 'My Wallet',
          },
        },
        pendingPayment: {
          myCollection: 'My Wallet',
        },
        transfer: {
          request: {
            description:
              'This option allows you to send your NFT to an external wallet. This will make your item leave OKEN and be fully controlled by this wallet. Fill in the fields below or learn more at the following link.',
          },
          pendingExternalTransfer: {
            description:
              'You have connected your wallet, but have not yet transferred your balances from your account. To do so, please start the migration process by clicking the button below. You will receive a link in your email to confirm the migration.\nNote that all balances in your OKEN account will be transferred, so please make sure you have access to your connected wallet. Once transferred, we will not be able to move them on your behalf.',
          },
          confirmation: {
            description: {
              part2:
                'The transferred tokens will be removed from your wallet when the transaction is confirmed.',
            },
          },
        },
      },
      components: {
        footer: {
          view: {
            sustainableNFT: 'Investment on tokenized assets',
          },
          data: {
            collection: 'Wallet',
          },
        },
        topBar: {
          myCollection: 'My Wallet',
        },
        rarumHeader: {
          whatIsRarum: 'What is OKEN?',
        },
      },
      core: {
        constants: {
          rarumDescription: [
            'OKEN Finance is a regulated investment platform authorized by the CVM under regulation 88. Our platform uses tokens to represent your assets, combining the benefits of Web3 with the capital market.',
            'Each item on the platform has its carbon consumption (carbon dioxide emissions, which are highly polluting, generated during the production and delivery of items) automatically offset using Carbon-Pegged Crypto Tokens.',
            'Our platform also takes responsibility for the security of your data and assets. For this reason, we use blockchain technology to ensure the validity of items traded on the platform. Each item generated corresponds to a unique record, which acts as a certificate of origin with a cryptographic signature from the creator of each item. This way, you can be sure that we use the latest technology to certify that the data is valid and the transactions are secure.',
            'If you want to know more about OKEN, you can contact us at info@oken.finance',
          ],
          dataDeletionInstructions: [
            'If you want to delete your data from our servers, you can remove them by following the instructions below:',
            '1. Go to "Settings and privacy" > "Settings";',
            '2. Then go to "Apps and websites";',
            '3. Remove "OKEN" from your apps;',
            '4. Send an email with the subject "DELETE MY DATA" to contact@oken.finance from the email you used to create your account. Your data will be removed from our servers within 1 business day.',
          ],
          shareTitle: 'OKEN - Digital assets',
          externalEnvironmentAlert:
            'You will be redirected to an external environment, the partner payment provider of OKEN. Follow the instructions for the selected payment method on the next screen.',
        },
      },
    },
  },
} satisfies {
  'en-US': OverwrittenOkenTranslations
}

const messages = merge(baseMessages, okenMessages, openspaceMessages)

export { messages }
