import useFirestoreWatch from '@onepercentio/one-ui/dist/hooks/useFirestoreWatch'
import { watchCVMForm } from 'core/modules/firebase/service'
import { ComponentProps, useMemo } from 'react'
import FormLogic from './Form/Form.logic'
import { useUser } from 'core/logic/user'
import { RarumUserProfile } from 'core/logic/user/user.types'

export function useAnswers(forceUpdate: boolean = false) {
  const { profile } = useUser()
  const documents = useFirestoreWatch(watchCVMForm, [])
  const savedAnswers = useMemo(() => {
    return documents?.reduce((r, { id, ...document }) => {
      return {
        ...r,
        ...document,
      }
    }, {} as ComponentProps<typeof FormLogic>['savedAnswers'])
  }, [!forceUpdate ? !!documents : documents])

  const cvm88Status = useMemo(() => {
    if (!documents) return
    if (profile?.cvm88Status === 'rejected') return 'rejected' as const
    else if (
      documents.length &&
      documents.reduce((r, doc) => r && doc.status === 'approved', true)
    )
      return 'approved'
    else if (
      documents.length &&
      documents.reduce((r, doc) => r && doc.status === 'pending', true)
    )
      return 'pending' as const
    else return 'incomplete' as const
  }, [documents, profile?.cvm88Status])

  const sentToReview = useMemo(() => {
    if (!documents || documents.length === 0) return false
    const someDocMissingStatus = documents.find((a) => a.status === undefined)
    return !someDocMissingStatus
  }, [documents])

  return {
    savedAnswers,
    cvm88Status: cvm88Status as RarumUserProfile['cvm88Status'] | 'incomplete',
    sentToReview,
  }
}
