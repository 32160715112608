import React from 'react'
import { IntroViewProps } from './Intro.types'
import Text from 'openspace/components/Text/Text'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import Container from 'openspace/components/Container/Container'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Button from '@onepercentio/one-ui/dist/components/Button'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Card from 'components/Card/Card'
import ProfileAvatarLogic from 'components/ProfileAvatar/ProfileAvatar.logic'
import { t } from 'translate/i18n'
import IconRarum from 'components/IconRarum/IconRarum.view'

import Styles from './Intro.module.scss'
import PingPongText from '@onepercentio/one-ui/dist/components/PingPongText/PingPongText'
import MutableHamburgerButton from '@onepercentio/one-ui/dist/components/MutableHamburgerButton/MutableHamburgerButton'
import { USER_PROFILE_STEP } from '../../UserProfile.types'
import Loader from '@onepercentio/one-ui/dist/components/Loader/Loader'

export function Status({
  status: s,
}: {
  status: 'complete' | 'incomplete' | 'loading'
}) {
  if (s === 'loading')
    return (
      <span className={`${Styles.status} ${Styles[s]}`}>
        <Loader />
      </span>
    )
  return (
    <span className={`${Styles.status} ${Styles[s]}`}>
      <MutableHamburgerButton
        state={s === 'complete' ? 'checkmark' : 'pencil'}
        size={16}
      />
      &nbsp;
      {t(`authenticated.userProfile.actions.${s}`)}
      &nbsp;
    </span>
  )
}

/**
 * Shows a resume of the information available for the user
 **/
export default function IntroView({
  user,
  onSeePurchaseHistory,
  onGoToStore,
  onSetupWallet,
  onCommunication,
  onDocuments,
  onInvestorProfile,
  // onPaymentData,
  onPersonalData,
  onLogout,
  completed,
  purchaseCount,
}: IntroViewProps) {
  return (
    <>
      <Container>
        <Spacing size='32' />
        <Text code='components.topBar.myData' type={FigmaTypo.H1} />
        <Spacing size='32' />
        <Card>
          <div className={Styles.row}>
            <ProfileAvatarLogic hideUsername/>
            {user && (
              <>
                <div>
                  <OneText type={FigmaTypo.H2}>{user.name}</OneText>
                  <OneText type={FigmaTypo.P16}>
                    {purchaseCount === undefined ? (
                      <Loader />
                    ) : purchaseCount > 0 ? (
                      <>
                        {t('authenticated.userProfile.purchases', {
                          count: user.purchases || 0,
                        })}
                        {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant='transparent-link'
                          onClick={onSeePurchaseHistory}
                          color='primary'>
                          {t('authenticated.userProfile.actions.history')}
                        </Button> */}
                      </>
                    ) : (
                      <>
                        {t('authenticated.userProfile.purchases_zero')}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Button
                          variant='transparent-link'
                          onClick={onGoToStore}
                          color='primary'>
                          {t('authenticated.userProfile.actions.seeStore')}
                        </Button>
                      </>
                    )}
                  </OneText>
                </div>
                <Button variant='outline' color='primary' onClick={onLogout}>
                  {t('components.topBar.exit')}
                </Button>
              </>
            )}
          </div>
        </Card>
        <Card className={`${Styles.wallet} dark`}>
          <div className={Styles.row}>
            <IconRarum icon='wallet' size={24} className={Styles.icon} />
            <div>
              <Text
                code='authenticated.userProfile.digitalWallet'
                type={FigmaTypo.P14}
              />
              <PingPongText type={FigmaTypo.H4}>
                {user?.wallet ||
                  t('authenticated.userProfile.digitalWalletNotYetConfigured')}
              </PingPongText>
            </div>
            <span />
            {/* <Button variant='transparent-link' onClick={onSetupWallet}>
              {t('authenticated.userProfile.actions.setup')}{' '}
              <IconRarum icon='chevronRight' size={24} />
            </Button> */}
          </div>
        </Card>
        <Spacing size='32' />

        <Card>
          <div className={Styles.row}>
            <IconRarum icon='user' className={Styles.icon} size={24} />
            <div>
              <Text
                code='authenticated.userProfile.sections.personalData.0'
                type={FigmaTypo.H4}
              />
              <Text
                code='authenticated.userProfile.sections.personalData.1'
                type={FigmaTypo.P14}
              />
            </div>
            <Button variant='transparent-link' onClick={onPersonalData}>
              <Status
                status={
                  completed[USER_PROFILE_STEP.PERSONAL_DATA] === undefined
                    ? 'loading'
                    : completed[USER_PROFILE_STEP.PERSONAL_DATA]
                    ? 'complete'
                    : 'incomplete'
                }
              />
              <IconRarum icon='chevronRight' size={24} />
            </Button>
          </div>
        </Card>
        <Spacing size='16' />
        <Card>
          <div className={Styles.row}>
            <IconRarum icon='upload' className={Styles.icon} size={24} />
            <div>
              <Text
                code='authenticated.userProfile.sections.myDocuments.0'
                type={FigmaTypo.H4}
              />
              <Text
                code='authenticated.userProfile.sections.myDocuments.1'
                type={FigmaTypo.P14}
              />
            </div>
            <Button variant='transparent-link' onClick={onDocuments}>
              <Status
                status={
                  completed[USER_PROFILE_STEP.DOCUMENTS] === undefined
                    ? 'loading'
                    : completed[USER_PROFILE_STEP.DOCUMENTS]
                    ? 'complete'
                    : 'incomplete'
                }
              />
              <IconRarum icon='chevronRight' size={24} />
            </Button>
          </div>
        </Card>
        <Spacing size='16' />
        <Card>
          <div className={Styles.row}>
            <IconRarum icon='investment' className={Styles.icon} size={24} />
            <div>
              <Text
                code='authenticated.userProfile.sections.investorProfile.0'
                type={FigmaTypo.H4}
              />
              <Text
                code='authenticated.userProfile.sections.investorProfile.1'
                type={FigmaTypo.P14}
              />
            </div>
            <Button variant='transparent-link' onClick={onInvestorProfile}>
              <Status
                status={
                  completed[USER_PROFILE_STEP.INVESTOR_PROFILE] === undefined
                    ? 'loading'
                    : completed[USER_PROFILE_STEP.INVESTOR_PROFILE]
                    ? 'complete'
                    : 'incomplete'
                }
              />
              <IconRarum icon='chevronRight' size={24} />
            </Button>
          </div>
        </Card>
        {/* <Spacing size='16' />
        <Card>
          <div className={Styles.row}>
            <IconRarum icon='card' className={Styles.icon} size={24} />
            <div>
              <Text
                code='authenticated.userProfile.sections.paymentData.0'
                type={FigmaTypo.H4}
              />
              <Text
                code='authenticated.userProfile.sections.paymentData.1'
                type={FigmaTypo.P14}
              />
            </div>
            <Button variant='transparent-link' onClick={onPaymentData}>
              <Status status='incomplete' />
              <IconRarum icon='chevronRight' size={24} />
            </Button>
          </div>
        </Card> */}
        <Spacing size='16' />
        <Card>
          <div className={Styles.row}>
            <IconRarum icon='security' className={Styles.icon} size={24} />
            <div>
              <Text
                code='authenticated.userProfile.sections.communication.0'
                type={FigmaTypo.H4}
              />
              <Text
                code='authenticated.userProfile.sections.communication.1'
                type={FigmaTypo.P14}
              />
            </div>
            <Button variant='transparent-link' onClick={onCommunication}>
              <IconRarum icon='chevronRight' size={24} />
            </Button>
          </div>
        </Card>
      </Container>
    </>
  )
}
