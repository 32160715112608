import { ClientContextShape } from 'context/ClientContext'
import { ReactComponent as Logo } from 'openspace/assets/clients/oken/logo.svg'

export default {
  home: {
    banner: '',
  },
  Logotype: Logo,
  typography: {
    font: 'Open Sans',
    headFont: 'Lexend',
  },
} as ClientContextShape
