import merge from 'lodash/merge'
import openspaceMessages from 'openspace/translate/languages/pt'
import { DeepPartial } from '@onepercentio/one-ui/dist/types'
import { TranslationShape, messages as baseMessages } from './rarum.pt'

const okenMessages = {
  'pt-BR': {
    translations: {
      features: {
        challenge: {
          claim: {
            confirmation: {
              deplete: `O item {{itemName}} não vai mais estar disponível na sua carteira, pois todas as unidades estão sendo utilizadas para o resgate.`,
              warning: `Os itens selecionados para o resgate serão transformados em um novo item e a quantidade disponível será reduzida em "Minha carteira"`,
            },
          },
        },
      },
      walletRequiredControl: {
        transferWallet: {
          fromEmailLink: {
            actions: {
              understoodDanger:
                'Estou ciente dos riscos e benefícios de usar uma carteira externa e de que a OKEN não terá mais acesso aos meus NFTs após a conclusão da migração.',
            },
          },
        },
        migrationRequired: {
          description:
            'Para seguir com essa operação, você precisa, em primeiro lugar, conectar os itens da sua carteira OKEN com a sua carteira Metamask.',
        },
      },
      marketplace: {
        offerCreation: {
          creation: {
            error: {
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@oken.finance',
            },
          },
          edition: {
            error: {
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@oken.finance',
            },
          },
          removal: {
            error: {
              description:
                'Tente novamente. Caso persista o erro, nos ajude a melhorar, entre em contato conosco pelo contact@oken.finance',
            },
          },
        },
        buyOffer: {
          whatIsRarum: 'O que é a OKEN?',
        },
        banner: {
          header: {
            description:
              'Conheça os itens à venda e todos os cards que podem fazer parte da sua Carteira',
          },
          faq: {
            title:
              '<b>Veja como vender</b> os itens disponíveis na sua carteira',
          },
        },
      },
      highlights: {
        marketplace: {
          description:
            'Aumente a sua Carteira, venda cards repetidos e complete sua carteira.',
        },
      },
      unauthenticated: {
        socialSign: {
          rarumInscription:
            'Ao se inscrever na OKEN, você reconhece que leu e concorda com todos os',
        },
        drops: {
          details: {
            info: {
              notAvailable:
                'Não há mais unidades disponíveis desse item. Selecione outro para colecionar mais itens com a OKEN.',
            },
          },
        },
        connect: {
          createAccount: 'Crie sua conta na OKEN',
          login: 'Faça login na OKEN',
        },
        faqview: {
          faq: {
            3: `Os cards são oferecidos de maneira randômica, através de pacotes. Escolha um dos pacotes das coleções disponíveis. Cada pacote contém um determinado número de cards aleatórios daquela oferta. Os pacotes se diferenciam por sua raridade e desejo. Quanto mais raro, maior o desejo de obter e em menor quantidade estará disponível.`,
            4: `Após selecionar um pacote, você será direcionado para a tela de compra. Escolha uma forma de pagamento e insira os dados solicitados. Assim que o pagamento for aprovado, nosso sistema entrega os NFTs para sua carteira de forma randômica e de acordo com a raridade escolhida: hero, premium e legend.`,
            5: `Pronto, você receberá seu pacote na sua carteira. Clique em ”abrir pacote” e revele seus cards. E já é possível, verificar sua propriedade na blockchain, com transparência e imutabilidade. Os cards ficarão armazenados em sua carteira e, em breve, você poderá negociá-los com outros colecionadores.`,
            accordion: {
              title: {
                '1': 'O que é blockchain?',
                '2': 'O que são tokens?',
                '3': 'O que são wallets?',
                '4': 'O que é RWA (Real World-Assets)?',
                '5': 'Em qual blockchain meus tokens são gerados?',
                '6': 'Quais são os meios de pagamento aceitos na plataforma?',
                '7': 'Preciso conhecer cripto e /ou blockchain para operar no Oken.Finance?',
                '8': 'O que é uma plataforma whitelabel?',
                '9': 'O que são valores mobiliários?',
                '10': 'O que são ativos tokenizados?',
                '11': 'Todas as ofertas são validades pela CVM?',
                '12': 'O que é a plataforma eletrônica de investimento participativo?',
                '13': 'Como investir pela plataforma?',
                '14': 'O que acontece após realizar o investimento pela plataforma?',
                '15': 'Como é determinado o sucesso do investimento?',
                '16': 'Qual é a forma de confirmação dos investimentos?',
                '17': 'O investidor tem direito de desistência do investimento?',
                '18': 'Quem pode investir na plataforma eletrônica de investimento?',
                last: 'Posso investir acima dos R$ 20 mil em ativos tokenizados?',
              },
              content: {
                '1': 'Um blockchain é essencialmente um livro digital de transações que é duplicado e distribuído pela rede de sistemas de computadores na Internet. Blockchain é um termo que tem sido muito utilizado quando se refere a uma nova infraestrutura tecnológica web3 (uma nova fase da internet) cujas principais características são: descentralização, segurança, transparência e desintermediação. Esta tecnologia permitiu a criação do Bitcoin em 2008, e agora tem sido usada como o elemento-chave da economia tokenizada, que é a digitalização total de qualquer ativo e aspecto da nossa economia.',
                '2': 'Os tokens são o componente essencial usado em aplicações em blockchain. Os tokens podem ser usados ​​para representar qualquer coisa, por exemplo, podem representar uma reserva de valor, um ativo digital ou do mundo real, como propriedade, arte, bens em uma cadeia de suprimentos e títulos. Não há limites para o que pode ser representado como um token.',
                '3': 'Uma carteira blockchain é uma carteira digital que os usuários podem usar para armazenar e gerenciar ativos digitais e criptomoedas (tokens). Uma carteira blockchain permite aos usuários transferir e gerenciar seus ativos digitais e moedas digitais, é também a forma como os usuários podem converter de volta seus ativos digitais na moeda local do usuário.',
                '4': 'Ativos do mundo real, também chamados de RWAs, são tokens que representam determinados ativos físicos ou não, podendo ser bens, objetos, títulos ou outros. Assim como outros tokens, eles funcionam no blockchain por meio de contratos inteligentes, um token pode equivaler a um objeto inteiro e também a uma parte/fração.',
                '5': 'Nossa plataforma é compatível com todas as redes compatíveis com o padrão Ethereum Virtual Machine (EVM), que inclui redes como Ethereum, Polygon, Celo, Polkadot e muitas outras.',
                '6': 'Nossa plataforma aceita os meios de pagamento comumente utilizados no mercado, incluindo PIX, Cartão de Crédito e Cripto (BRLA, USDC).',
                '7': 'Com a Oken Finance, o acesso ao mundo web3 é simples. Nossas soluções são projetadas para gerenciar toda a complexidade do blockchain para você, sem a necessidade de entender como ele funciona ou sem uma carteira externa para gerenciar seus ativos digitais. No entanto, usuários mais avançados podem conectar suas carteiras como a Metamask e usar suas criptomoedas se desejarem. ',
                '8': 'Whitelabel é uma plataforma que sua empresa pode customizar para lançar seu próprio marketplace em blockchain, sem nenhum esforço de desenvolvimento. É a maneira mais fácil de adotar a estratégia web3 para sua empresa e lançar seus próprios ativos digitais para explorar as oportunidades de mercado.',
                '9': 'Os valores mobiliários representados pelos tokens podem ser ativos subjacentes tais como instrumentos de investimento em participação societária em Startups (Equity), assim como títulos de securitização, representativos, vinculados ou lastreados em direitos creditórios ou títulos de dívida emitidos por Startups, conhecidos como “tokens de recebíveis” ou “tokens de renda fixa”.',
                '10': 'Tokenização é o processo de representação dos ativos reais na forma de tokens digitais e plataforma eletrônicas que utilizam blockchain. Assim como a matrícula de um imóvel em um cartório de registro de imóveis representa as informações sobre determinado imóvel, um token em blockchain representa um determinado ativo, que pode ser um % de equity, um direito creditório, um precatório, um título de renda fixa, entre tantas outras possibilidadesa.',
                '11': 'As plataformas eletrônicas de investimento licenciadas pela CVM 88 operam dentro de acordo com limites da regulamentação, sendo que dentro destes limites não há necessidade de registro e aprovação de cada oferta pública, desde que respeitados os limites e regras da CVM88. ',
                '12': 'A Plataforma exerce atividade de plataforma eletrônica de investimento participativo (Crowdfunding), sendo autorizada pela Comissão de Valores Mobiliários (“CVM”) para realizar ofertas públicas e distribuição de tokens lastreados em valores mobiliários de emissão de Empresas de Pequeno Porte (EPPs) (i.e., Startups), automaticamente dispensadas de registro na CVM, de acordo com a Resolução CVM 88.',
                '13': 'Os investidores acessam a plataforma e fazem um login/identificação por meio de seu login social ou e-mail. A plataforma disponibiliza as informações sobre as ofertas públicas ativas, detalhando as informações sobre a captação, perfil das ofertas, informações sobre o investimento, e demais informações específicas da oferta. Caso o investidor ainda não tenha realizado o cadastro completo e preenchimento do seu perfil, antes de avançar para o investimento será necessário completar todas as informações para que seja conduzido o processo de cadastro (KYC). Após a reserva do investimento, o investidor recebe os dados de uma conta de pagamento, criada em seu nome, para realizar a transferência do montante reservado. ',
                '14': 'O montante fica nessa conta até o momento de conclusão da oferta, quando é transferido para a conta da empresa emissora dos valores mobiliários ou restituído à conta de titularidade do investidor, que pode transferi-lo para outra conta bancária de mesma titularidade a qualquer momento. Se a oferta não for concluída por não atingir o valor mínimo necessário, que é sempre ⅔ (dois terços) do valor alvo de captação, os valores investidos e pagos retornarão para os investidores, na mesma conta corrente / de pagamento de origem em nome do investidor.',
                '15': 'Para uma oferta ser bem-sucedida, a empresa emissora dos valores mobiliários deve alcançar, dentro do prazo limite da oferta de 180 dias, o valor mínimo de ⅔ (dois terços) do valor alvo da captação em investimentos confirmados (“Oferta Parcial”). Mesmo depois de atingir os ⅔ (dois terços) do valor da captação, a oferta poderá continuar ativa para tentar chegar no valor total alvo da captação (“Oferta Total”), respeitando o prazo limite de 180 dias do início da oferta.',
                '16': 'O investimento é confirmado pela assinatura do Termo de Adesão ao contrato de investimento, ou contrato de mútuo, ou instrumento de dívida, o que for legalmente adequado para a oferta que está sendo apresentada, gerado no momento da reserva, ou pela transferência do valor reservado à conta de pagamento informada pela Plataforma. ',
                '17': 'O investidor tem até 5 (cinco) dias para desistir do investimento, sem incorrer em qualquer multa ou penalidade, após a confirmação do investimento e pagamento dos valores. Caso os recursos já tenham sido transferidos para a conta de pagamentos criada em nome do investidor, a Plataforma providenciará o retorno dos recursos ao investidor, na mesma conta corrente por ele utilizada para realizar o pagamento do investimento, de sua titularidade, no prazo de até 5 (cinco) dias úteis.',
                '18': 'Pessoas físicas ou jurídicas podem investir até R$ 20.000,00 (vinte mil reais) via crowdfunding de investimentos por ano, considerando para tanto o total investido por meio de quaisquer outras plataformas. A cada investimento, o investidor deve assinar uma declaração (conforme anexo C da Resolução CVM 88) em que afirma que o valor investido na Plataforma somado a outros investimentos de mesma natureza não ultrapassa o referido montante.',
                last: 'Sim, desde que você atenda a estes critérios: a) Possuir patrimônio financeiro ou renda bruta anual superior a R$ 200.000,00 (duzentos mil reais) e confirmar que o montante investido naquele ano, considerando todas as plataformas, não ultrapassará 10% de seu patrimônio financeiro ou renda bruta anual, o que for maior; b) Ser um investidor qualificado – possuir investimentos financeiros superiores a R$ 1.000.000,00 (um milhão de reais) e possuir conhecimento sobre o mercado financeiro. Neste caso, não há limite anual de investimento e o investidor deve assinar a declaração constante do anexo A da Resolução CVM 88.',
              },
            },
          },
        },
        claim: {
          errors: {
            alreadyClaimed: {
              actions: {
                showItem: 'Mostrar na carteira',
              },
            },
          },
        },
      },
      authenticated: {
        myCollection: {
          myCollection: 'MINHA CARTEIRA',
          accessDetails:
            'Acesse e consulte os detalhes dos itens da sua carteira.',
          collectionItemDetails: {
            noBalance: {
              action: {
                backToMyCollection: 'Ver minha carteira',
              },
            },
          },
          emptyCollection: {
            empty: 'Você ainda não possui itens na sua carteira.',
          },
        },
        openingDrop: {
          message3:
            'Você pode visualizar todos os itens da sua carteira diretamente na blockchain. Se ficar com dúvidas sobre onde buscar os dados, visite nosso FAQ ou entre em contato',
          modals: {
            errorModal: {
              myCollection: 'Minha Carteira',
              tryAgain:
                'Por favor tente novamente mais tarde, ou entre em contato com a OKEN através de contact@oken.finance.',
            },
            exitModal: {
              confirmToExitMessage:
                'Após o término do processo de carregamento os itens do item estarão disponíveis em Minha Carteira.',
            },
            timeOutModal: {
              myCollection: 'VOLTAR À MINHA CARTEIRA',
              waitingMessage1:
                'Fique tranquilo(a), seus itens estarão disponíveis em sua carteira em breve.',
              waitingMessage2:
                'Por que essa demora pode acontecer? O processo de abertura é executado na blockchain e pode variar de acordo com o volume de transações na rede neste momento. Aguarde alguns minutos ou volte à sua carteira em breve.',
            },
          },
          opened: {
            myCollection: 'Minha Carteira',
          },
        },
        pendingPayment: {
          myCollection: 'Minha Carteira',
        },
        transfer: {
          confirmation: {
            description: {
              part2:
                'Os tokens enviados ao endereço serão debitados da sua carteira assim que a transação for confirmada na blockchain.',
            },
          },
          request: {
            description:
              'Essa opção permite que você envie seu NFT para uma carteira externa. Isso fará com que seu item saia da OKEN e passe a ser controlado totalmente por essa carteira. Preencha os campos abaixo ou saiba mais no link a seguir.',
          },
          pendingExternalTransfer: {
            description: `Você conectou sua carteira, mas ainda não transferiu seus saldos da sua conta. Para fazê-lo, por favor, inicie o processo de migração clicando no botão abaixo. Você receberá um link em seu e-mail para confirmar a migração.
Note que todos os saldos em sua conta OKEN serão transferidos, então por favor verifique se você tem acesso a sua carteira conectada. Uma vez transferidos, não poderemos movê-los em seu nome.`,
          },
        },
        userProfile: {
          communication: {
            cancel: {
              description:
                'Cancele sua conta e exclua suas informações da plataforma OKEN',
            },
          },
        },
      },
      components: {
        footer: {
          view: {
            sustainableNFT: 'Investimento em ativos tokenizados',
          },
          data: {
            collection: 'Carteira',
          },
        },
        topBar: {
          myCollection: 'Minha Carteira',
        },
        rarumHeader: {
          whatIsRarum: 'O que é OKEN?',
        },
      },
      core: {
        constants: {
          rarumDescription: [
            'A OKEN Finance é uma plataforma de investimentos em ativos autorizada pela CVM dentro da regulação 88. Nossa plataforma utiliza tokens para representar seus ativos, unindo os benefícios da Web3 com o mercado de capitais.',
            'Cada item na plataforma tem seu consumo de carbono (emissões de dióxido de carbono, que são extremamente poluentes, geradas durante a produção e entrega dos itens) automaticamente compensado, utilizando Carbon-Pegged Crypto Tokens.',
            'Nossa plataforma também se responsabiliza pela segurança dos seus dados e dos seus ativos. Por esse motivo, usamos a tecnologia blockchain para garantir a validade dos itens comercializados na plataforma. Cada item gerado corresponde a um registro único, que vale como uma espécie de certificado de origem, com uma assinatura criptográfica de quem criou cada item. Dessa forma, você pode ter certeza que colocamos o que há de mais moderno para certificar que os dados são válidos e as transações são seguras.',
            'Caso queira saber mais sobre a OKEN, você pode entrar em contato conosco pelo e-mail info@oken.finance',
          ],
          dataDeletionInstructions: [
            'Se quiser excluir seus dados de nossos servidores, você pode removê-las seguindo as instruções a seguir:',
            '1. Vá em "Configurações e privacidade" > "Configurações";',
            '2. Em seguida vá em "Aplicativos e sites";',
            '3. Remova "OKEN" dos seus aplicativos;',
            '4. Envie um e-mail com assunto "DELETAR MEUS DADOS" para contact@oken.finance a partir do e-mail com o qual você criou a sua conta. Seus dados serão removidos de nossos servidores dentro de 1 dia útil.',
          ],
          shareTitle: 'OKEN - Ativos digitais',
          externalEnvironmentAlert:
            'Você será redirecionado para um ambiente externo, do provedor de pagamentos parceiro da OKEN. Siga as instruções do meio de pagamento selecionado na próxima tela.',
        },
      },
    },
  },
} satisfies {
  'pt-BR': DeepPartial<TranslationShape>
} & any

const messages = merge(baseMessages, okenMessages, openspaceMessages)

export type OverwrittenOkenTranslations = (typeof okenMessages)['pt-BR']
export { messages }
