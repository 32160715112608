import { lazy, Suspense } from 'react'
import Container from 'openspace/components/Container'
import { HomeViewProps } from './Home.types'
import Styles from './Home.oken.module.scss'
import Text from '@onepercentio/one-ui/dist/components/Text'
import { tO } from 'translate/i18n'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider'
import LazyBadge from 'openspace/components/LazyBadge'
import Footer from 'components/Footer'
import { ReactComponent as OkenBanner } from 'openspace/assets/clients/oken/banner-modern.svg'
import BannerJPG from 'openspace/assets/clients/oken/banner.jpg'
import { useFeatures } from 'context/Feature'
import { Scaled } from 'openspace/assets/png/partnership_bg.png'
import {
  GalleriesSection,
  LatestItensSection,
} from './Home.view.tsx'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

const LogoType = lazy(() => import('../../../components/Background/BG'))

function Partnership() {
  return (
    <Container className={Styles.partnership}>
      <div>
        <Suspense fallback={<svg />}>
          <LogoType className={Styles.logo} />
        </Suspense>
        <img src={Scaled['1x']} alt='' className={Styles.logoBg} />
        <LazyBadge className={Styles.badge} />
      </div>
      <div>
        <Text type={FigmaTypo.H2}>{tO('home.sections.partnership.title')}</Text>
        <Text type={FigmaTypo.P16}>
          {tO('home.sections.partnership.description')}
        </Text>
      </div>
    </Container>
  )
}

/**
 * Where the user checks out the galeries
 **/
export default function HomeView(props: HomeViewProps) {
  const { hasGalleries } = useFeatures()
  return (
    <>
      <div className={`${Styles.okenBanner} dark`}>
        <Container>
          <OkenBanner style={{ backgroundImage: `url(${BannerJPG})` }} />
        </Container>
      </div>
      <Spacing size='40' />
      {/* <HighlightsSection drops={props.highlightsPaginable} /> */}
      {hasGalleries && (
        <GalleriesSection
          key={'g'}
          onSeeAll={() => props.onSeeAll('gallery')}
        />
      )}
      <LatestItensSection
        key={'a'}
        drops={props.dropsPaginable}
        containerRef={props.dropsContainerRef}
      />
      <Partnership />
      <Footer />
    </>
  )
}
