import React, {
  ComponentProps,
  ElementRef,
  Fragment,
  PropsWithChildren,
  useMemo,
  useRef,
  useState,
} from 'react'
import View from './UserProfile.view'
import { USER_PROFILE_STEP, UserProfileProps } from './UserProfile.types'
import IntroLogic from './Steps/Intro/Intro.logic'
import { CVM88Section } from 'features/CVM88'
import { FormMode, FormStep } from 'features/CVM88/Form/Form.types'
import MainGrid from '@onepercentio/one-ui/dist/components/MainGrid'
import OneText from '@onepercentio/one-ui/dist/components/Text'
import Button from '@onepercentio/one-ui/dist/components/Button'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import BackButtonView from 'components/BackButton/BackButton.view'
import { t } from 'translate/i18n'
import { FigmaTypo } from 'containers/OneUIProvider/OneUIProvider.data'
import { Status } from './Steps/Intro/Intro.view'
import useSnapToViewport from '@onepercentio/one-ui/dist/hooks/ui/useSnapToViewport'
import Styles from './UserProfile.module.scss'
import {
  areAllQuestionsAnswered,
  useCVMForm,
} from 'features/CVM88/Form/Form.hook'
import { useAnswers } from 'features/CVM88/CVM88.hook'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'
import Container from 'openspace/components/Container/Container'
import Text from 'openspace/components/Text/Text'
import CommunicationLogic from './Steps/Communication/Communication.logic'

export default function UserProfileLogic(props: UserProfileProps) {
  const uncontrolledRef =
    useRef<ElementRef<typeof UncontrolledTransition>>(null)
  const [currentStep, setStep] = useState(USER_PROFILE_STEP.INTRO)
  const { savedAnswers } = useAnswers(true)
  const { getQuestionsForStep } = useCVMForm(savedAnswers || {})
  const completedSteps = useMemo(() => {
    if (!savedAnswers) return {}
    return {
      [USER_PROFILE_STEP.PERSONAL_DATA]:
        areAllQuestionsAnswered(
          getQuestionsForStep(FormStep.PERSONAL_DATA),
          savedAnswers!
        ).isValid &&
        areAllQuestionsAnswered(
          getQuestionsForStep(FormStep.ADDITIONAL_DATA),
          savedAnswers!
        ).isValid,
      [USER_PROFILE_STEP.DOCUMENTS]: areAllQuestionsAnswered(
        getQuestionsForStep(FormStep.DOCUMENTS),
        savedAnswers!
      ).isValid,
      [USER_PROFILE_STEP.INVESTOR_PROFILE]: areAllQuestionsAnswered(
        getQuestionsForStep(FormStep.INVEST_PROFILE),
        savedAnswers!
      ).isValid,
    }
  }, [savedAnswers])
  const [editStep, setEditStep] = useState<FormStep>()
  const step = useMemo(() => {
    const goToStep =
      (s: USER_PROFILE_STEP, animationDirection: 'forward' | 'backward') =>
      () => {
        setStep(s)
        uncontrolledRef.current!.setOrientation(animationDirection)
      }
    if (editStep !== undefined)
      return (
        <Container className={Styles.edition} key={`edit_${editStep}`}>
          <Spacing size='32' />
          <Text
            code='authenticated.userProfile.profileUpdate'
            type={FigmaTypo.H2}
          />
          <Spacing size='32' />
          <CVM88Section
            step={editStep}
            mode={FormMode.WRITE}
            onCancel={() => {
              uncontrolledRef.current!.setOrientation('backward')
              setEditStep(undefined)
            }}
            onFinish={() => {
              uncontrolledRef.current!.setOrientation('backward')
              setEditStep(undefined)
              setStep(USER_PROFILE_STEP.INTRO)
            }}
          />
        </Container>
      )
    switch (currentStep) {
      case USER_PROFILE_STEP.COMMUNICATION:
        return (
          <HeadedScreen
            key='comm'
            title={t('authenticated.userProfile.sections.communication.0')}
            description={t(
              'authenticated.userProfile.sections.communication.1'
            )}
            onGoBack={goToStep(USER_PROFILE_STEP.INTRO, 'backward')}>
            <CommunicationLogic />
          </HeadedScreen>
        )
      case USER_PROFILE_STEP.INTRO:
        return (
          <IntroLogic
            key={'intro'}
            completed={completedSteps}
            onCommunication={goToStep(
              USER_PROFILE_STEP.COMMUNICATION,
              'forward'
            )}
            onDocuments={goToStep(USER_PROFILE_STEP.DOCUMENTS, 'forward')}
            onInvestorProfile={goToStep(
              USER_PROFILE_STEP.INVESTOR_PROFILE,
              'forward'
            )}
            onPersonalData={goToStep(
              USER_PROFILE_STEP.PERSONAL_DATA,
              'forward'
            )}
          />
        )
      case USER_PROFILE_STEP.PERSONAL_DATA:
        return (
          <HeadedScreen
            key='grid'
            onGoBack={goToStep(USER_PROFILE_STEP.INTRO, 'backward')}
            title={t('authenticated.userProfile.sections.personalData.0')}
            description={t('authenticated.userProfile.sections.personalData.1')}
            status={
              completedSteps[USER_PROFILE_STEP.PERSONAL_DATA]
                ? 'complete'
                : 'incomplete'
            }>
            <CVM88Section
              step={[FormStep.PERSONAL_DATA]}
              mode={FormMode.READ_ONLY}
            />
            <Spacing size='16' />
            <Button
              color='primary'
              variant='filled'
              onClick={() => setEditStep(FormStep.PERSONAL_DATA)}>
              {t('authenticated.userProfile.actions.changePersonalData')}
            </Button>
            <Spacing size='32' />
            <CVM88Section
              step={[FormStep.ADDITIONAL_DATA]}
              mode={FormMode.READ_ONLY}
            />
            <Spacing size='16' />
            <Button
              color='primary'
              variant='filled'
              onClick={() => setEditStep(FormStep.ADDITIONAL_DATA)}>
              {t('authenticated.userProfile.actions.changeAdditionalData')}
            </Button>
            <Spacing size='32' />
          </HeadedScreen>
        )
      case USER_PROFILE_STEP.DOCUMENTS:
      case USER_PROFILE_STEP.INVESTOR_PROFILE:
        const preffix =
          currentStep === USER_PROFILE_STEP.DOCUMENTS
            ? 'myDocuments'
            : 'investorProfile'
        const step =
          currentStep === USER_PROFILE_STEP.DOCUMENTS
            ? FormStep.DOCUMENTS
            : FormStep.INVEST_PROFILE
        const action: TranslationCodes =
          currentStep === USER_PROFILE_STEP.DOCUMENTS
            ? 'authenticated.userProfile.actions.changeDocuments'
            : 'authenticated.userProfile.actions.changeInvestorProfile'
        return (
          <HeadedScreen
            key='grid'
            onGoBack={goToStep(USER_PROFILE_STEP.INTRO, 'backward')}
            title={t(`authenticated.userProfile.sections.${preffix}.0`)}
            description={t(`authenticated.userProfile.sections.${preffix}.1`)}
            status={completedSteps[currentStep] ? 'complete' : 'incomplete'}>
            <CVM88Section step={step} mode={FormMode.READ_ONLY} />
            <Spacing size='16' />
            <Button
              color='primary'
              variant='filled'
              onClick={() => setEditStep(step)}>
              {t(action)}
            </Button>
            <Spacing size='32' />
          </HeadedScreen>
        )
    }
  }, [currentStep, editStep, completedSteps])
  return <View ref={uncontrolledRef}>{step}</View>
}

function HeadedScreen({
  children,
  onGoBack,
  title,
  description,
  status,
}: PropsWithChildren<{
  onGoBack: () => void
  title: string
  description: string
  status?: ComponentProps<typeof Status>['status']
}>) {
  const headRef = useSnapToViewport(0)

  return (
    <Container>
      <MainGrid
        leftContent={
          <div className={Styles.backBtn} key='back'>
            <BackButtonView text={t('generic.goBack')} onClick={onGoBack} />
          </div>
        }
        key={'static'}>
        <Fragment key='static'>
          <div className={Styles.heading} ref={headRef}>
            <OneText type={FigmaTypo.H2}>{title}</OneText>
            <OneText type={FigmaTypo.P14}>{description}</OneText>
            {status && <Status status={status} />}
          </div>
          <div className={Styles.content}>{children}</div>
        </Fragment>
      </MainGrid>
    </Container>
  )
}
