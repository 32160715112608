import { i18n } from 'translate/i18n'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import LinkMUI from '@material-ui/core/Link'
import clsx from 'clsx'
import { memo, ReactNode } from 'react'
import useStyles from './Footer.style'
import { Link } from 'react-router-dom'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { SHARE_URL } from 'core/constants'
import OkenLogo from 'openspace/assets/clients/oken/full.svg'
import Spacing from '@onepercentio/one-ui/dist/components/Spacing/Spacing'

export interface FooterViewProps {
  linksLeft: ReactNode[]
  linksRight: ReactNode[]
}

const Footer: React.FC<FooterViewProps> = ({ linksLeft, linksRight }) => {
  const classes = useStyles()
  const { tenant } = useTenant()

  const RarumLogo = (
    <>
      <LinkMUI rel='noopener noreferrer' target='_blank' href={SHARE_URL}>
        <img src={OkenLogo} alt={tenant?.displayName} style={{ height: 32 }} />
      </LinkMUI>
      <Typography
        variant='body2'
        component='p'
        className={clsx(classes.text, classes.rarumText)}>
        {i18n.t('components.footer.view.sustainableNFT')}
      </Typography>
    </>
  )

  const RarumText = (
    <Typography variant='body2' component='p' className={classes.text}>
      Copyright © {tenant?.displayName} {new Date().getFullYear()}
      <br />
      <Link
        to={{ pathname: 'https://onepercent.io/' }}
        target='_blank'
        style={{ textDecoration: 'none' }}
        className={classes.text}>
        {i18n.t('components.footer.view.oneAndMoss')}
      </Link>
    </Typography>
  )

  const ClientText = (
    <Typography variant='body2' component='p' className={classes.text}>
      Copyright © {tenant?.displayName} {new Date().getFullYear()}
      <br />
      {i18n.t('components.footer.view.officialProduct')}
    </Typography>
  )

  return (
    <footer className={classes.footer}>
      <Container maxWidth='lg' disableGutters>
        <Grid container spacing={0}>
          {/* Rarum group */}
          <Grid
            item
            xs={12}
            md
            className={clsx(classes.companyItem, classes.rarumItem)}>
            <Box className={classes.logoWrapper}>{RarumLogo}</Box>
            {RarumText}
          </Grid>
          {/* Client group */}
          <Grid
            item
            xs={12}
            md
            className={clsx(classes.companyItem, classes.clientItem)}>
            <Spacing size='stretch' />
            {ClientText}
          </Grid>
          {/* Links group */}
          <Grid
            container
            item
            xs={12}
            md={12}
            lg={5}
            className={classes.linksContainer}>
            {/* Left links column */}
            <Grid item xs className={classes.linksColumnLeft}>
              {linksLeft}
            </Grid>
            {/* Right links column */}
            <Grid item xs className={classes.linksColumnRight}>
              {linksRight}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default memo(Footer)
