import useCustomHistory from '@onepercentio/one-ui/dist/hooks/useCustomHistory'
import { useMarketplaceUser } from 'context/MarketplaceUser'
import { Sale } from 'core/logic/asset/asset.types'
import {
  TO_COLLECTION_ITEM_DETAIL,
  TO_MARKETPLACE_OFFER_DETAILS,
} from 'core/modules/router'
import { ComponentProps, useEffect, useState } from 'react'
import ConfirmView, { CONFIRMATION_STATES } from './Confirm.view'
import { useMarketplace } from 'context/Marketplace'

export default function ConfirmLogic({
  offer,
  asset,
}: Pick<ComponentProps<typeof ConfirmView>, 'offer' | 'asset'>) {
  const [isAllowedForTransfer, setISAllowed] = useState<boolean>()
  const [finalOfferId, setOfferId] = useState<Sale['offerId']>()
  const {
    getNftMovementAllowance,
    control,
    approveNFTManagement,
    createOfferOnly: _createOfferOnly,
  } = useMarketplaceUser()
  const { sales } = useMarketplace()

  const history = useCustomHistory()

  useEffect(() => {
    getNftMovementAllowance()
      .then(setISAllowed)
      .catch(() => {})
  }, [getNftMovementAllowance])

  function _approveNFTManagement() {
    approveNFTManagement()
      .then(setISAllowed)
      .catch(() => {})
  }

  function _createOffer() {
    _createOfferOnly(offer)
      .then(setOfferId)
      .then(() => sales.getPage(0, 10, asset.id))
      .catch(() => {})
  }

  function _goToOffer() {
    history.replace(TO_MARKETPLACE_OFFER_DETAILS(asset.id, finalOfferId!))
  }

  function _retry() {
    if (isAllowedForTransfer === undefined)
      getNftMovementAllowance()
        .then(setISAllowed)
        .catch(() => {})
    if (isAllowedForTransfer === false) _approveNFTManagement()
    if (isAllowedForTransfer === true) _createOffer()
  }
  return (
    <ConfirmView
      offer={offer}
      asset={asset}
      loading={control.loading}
      error={!!control.error}
      state={
        !isAllowedForTransfer
          ? CONFIRMATION_STATES.CHECKING_APPROVAL
          : CONFIRMATION_STATES.READY_FOR_CONFIRMING
      }
      onApprove={_approveNFTManagement}
      onCreate={_createOffer}
      offerId={finalOfferId}
      onGoToOffer={_goToOffer}
      onRetry={_retry}
      onGoBack={() =>
        history.goBackWithFallback(
          TO_COLLECTION_ITEM_DETAIL(asset.id, asset.tokenId)
        )
      }
    />
  )
}
