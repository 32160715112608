import {
  useState,
  createContext,
  useContext,
  PropsWithChildren,
  useEffect,
  useMemo,
} from 'react'
import {
  AssetType,
  MarketplaceAssetType,
  MarketplaceInfoResume,
  Sale,
} from 'core/logic/asset/asset.types'
import {
  Paginable,
  useLocalPagination,
} from '@onepercentio/one-ui/dist/hooks/usePagination'
import { useBatchBalances, useOnChainOffers } from 'core/logic/token/token.hook'
import { useAssets } from 'core/logic/asset/asset.hook'
import { ERC20ABI } from 'core/constants'
import { StableTokenData } from 'core/logic/token/token.types'
import { useTenant } from 'core/logic/tenant/tenant.hook'
import { normalizePrice } from 'core/helpers/contract'
import useRouteGalleryOrDefault from 'openspace/hooks/useRoutePathGallery'
import useWeb3Provider from 'hooks/useWeb3Provider'
import { useStable } from './Chain'

export type MarketplaceContextShape = {
  currencies: CryptoCurrencySymbol[]

  CurrencyContracts: {
    [k: CryptoCurrencySymbol]: Contract.IERC20
  }

  currencyDetails: {
    [k: CryptoCurrencySymbol]: {
      address: string
      decimals: number
    }
  }
  /**
   * Used for the listing of the marketplace
   */
  salesResume:
    | {
        [assetId in AssetType['id']]: MarketplaceInfoResume | undefined
      }
    | undefined

  /**
   * Indicates if the sales resume are loading or error
   */
  salesResumeControl: {
    loading: boolean
    error: string | undefined
    onRetry: () => void
  }

  /**
   * Used for the listing when entering the details of a sale
   */
  sales: Paginable<
    Sale & { assetId: string },
    [AssetType['id'] | AssetType['id'][] | undefined]
  >

  tax: number | undefined
}
export const MarketplaceContext = createContext<MarketplaceContextShape>(
  null as any
)

export function MarketplaceProvider({ children }: PropsWithChildren<{}>) {
  const { tenant } = useTenant()
  const stable = useStable()
  const gallery = useRouteGalleryOrDefault()

  const { nft, sales } = useMemo(() => {
    return {
      sales: tenant?.sales?.polygon,
      nft: gallery?.smartContract?.address,
    }
  }, [gallery, tenant])

  const { offers, fee, refreshOffers } = useOnChainOffers(sales, nft)
  const { assets } = useAssets()
  const offerSellers = useMemo(() => {
    if (offers) {
      return {
        accounts: offers.map((offer) => offer.paymentOptions.beneficiary),
        ids: offers.map((offer) => offer.deliveryOptions.tokenId.toString()),
      }
    }
    return { accounts: [], ids: [] }
  }, [offers])

  const { batchBalances } = useBatchBalances(
    offerSellers.accounts,
    offerSellers.ids,
    gallery?.smartContract?.address
  )
  const [assetSalesMap, setAssetSalesList] = useState<
    MarketplaceContextShape['sales']['items']
  >([])
  const [salesSummary, setSalesSummary] = useState<{
    [id: string]: MarketplaceInfoResume
  }>({})

  const web3MetamaskProvider = useWeb3Provider()

  const getTokenData = (address: string) =>
    stable.find((token: StableTokenData) => token.address === address)!

  const CurrencyContracts = useMemo(() => {
    if (!web3MetamaskProvider) return {}
    return stable.reduce(
      (r, token) => ({
        ...r,
        [token.symbol]: new web3MetamaskProvider.eth.Contract(
          ERC20ABI,
          token.address
        ) as any,
      }),
      {} as { [c: CryptoCurrencySymbol]: Contract.IERC20 }
    )
  }, [web3MetamaskProvider])

  useEffect(() => {
    if (offers && assets) {
      //Offers summary
      let assetSummaryMap: { [id: string]: MarketplaceInfoResume } = {}
      let assetListMap: { [id: string]: Sale[] } = {}
      assets.forEach((asset) => {
        const lowestPriceByToken: { [symbol: string]: { lowest: number } } = {}
        let activeOffersCountByToken: number = 0
        let totalOffersCountByToken: number = 0

        const offersByTokenId = offers.filter(
          (offer) => offer.deliveryOptions.tokenId === asset.tokenId
        )
        if (!offersByTokenId.length) return

        stable.forEach((token: StableTokenData) => {
          const tokenOffers = offersByTokenId.filter(
            (offer) =>
              getTokenData(offer.paymentOptions.paymentToken)?.symbol ===
              token.symbol
          )

          if (offersByTokenId.length) {
            const lowest = tokenOffers.sort(
              (a, b) => a.paymentOptions.price - b.paymentOptions.price
            )[0]
            lowestPriceByToken[token.symbol] = {
              lowest: normalizePrice(
                lowest?.paymentOptions.price,
                getTokenData(lowest?.paymentOptions.paymentToken).decimals
              ),
            }
          }
        })
        activeOffersCountByToken = offersByTokenId.filter(
          (offer) => offer.active
        ).length

        totalOffersCountByToken = offersByTokenId.length

        assetSummaryMap[asset.id] = {
          price: lowestPriceByToken,
          remaining: activeOffersCountByToken,
          supply: totalOffersCountByToken,
          marketplace: true,
        }
        if (!assetListMap[asset.id]) assetListMap[asset.id] = []
        assetListMap[asset.id].push(
          ...offersByTokenId.reduce((offers, offer) => {
            return [
              ...offers,
              ...new Array(offer.supply - offer.totalSold)
                .fill(undefined)
                .map(() => ({
                  price: normalizePrice(
                    offer.paymentOptions.price,
                    getTokenData(offer.paymentOptions.paymentToken).decimals
                  ),
                  user: offer.paymentOptions.beneficiary,
                  wallet: offer.paymentOptions.beneficiary,
                  offerId: offer.id,
                  currency: getTokenData(offer.paymentOptions.paymentToken)
                    .symbol,
                  tokenId: offer.deliveryOptions.tokenId,
                  remaining: offer.supply - offer.totalSold,
                })),
            ]
          }, [] as Sale[])
        )
      })
      setAssetSalesList(
        Object.keys(assetListMap).reduce(
          (acc, assetId) => [
            ...acc,
            ...assetListMap[assetId].map((a) => ({ ...a, assetId })),
          ],
          [] as (Sale & { assetId: string })[]
        )
      )
      setSalesSummary(assetSummaryMap)
    }
  }, [offers, assets])

  return (
    <MarketplaceContext.Provider
      value={{
        currencies: stable.map((token) => token.symbol),
        currencyDetails: stable.reduce(
          (r, c) => ({
            ...r,
            [c.symbol]: { decimals: c.decimals, address: c.address },
          }),
          {}
        ),
        sales: {
          getNextPage() {
            refreshOffers()
          },
          getAll() {
            refreshOffers()
          },
          items: assetSalesMap,
          sellerHasBalance(seller: string, tokenId: string) {
            for (let i in batchBalances) {
              if (i === seller) {
                const idx: number | undefined = batchBalances[
                  i
                ].tokens?.findIndex((id) => id === tokenId)
                if (idx !== -1 && idx !== undefined) {
                  return batchBalances?.[i]?.balances?.[idx] === '0'
                } else {
                  return false
                }
              }
            }
            return false
          },
        } as any,
        salesResume: salesSummary,
        salesResumeControl: {
          loading: false,
          error: undefined,
          onRetry: () => {},
        },
        CurrencyContracts,
        tax: fee,
      }}>
      {children}
    </MarketplaceContext.Provider>
  )
}

export function useMarketplace() {
  return useContext(MarketplaceContext)
}

export function useMarketplaceAssets(itemsPerPage: number) {
  const { salesResume, currencies } = useMarketplace()
  const { assets } = useAssets()
  const gallery = useRouteGalleryOrDefault()

  const items = useMemo(() => {
    const marketplaceAssets = assets
      ?.map((asset) => {
        if (gallery && asset.galleryId !== gallery.id) return undefined
        const salesResumeForAsset = salesResume && salesResume[asset.id]
        if (salesResumeForAsset) {
          const currency = currencies[0]
          const priceInformationByCurrency = salesResumeForAsset.price[currency]
          if (priceInformationByCurrency) {
            const marketplaceResume: MarketplaceInfoResume = {
              ...salesResumeForAsset,
              price: {
                [currencies[0]]: {
                  lowest: priceInformationByCurrency!.lowest,
                },
              },
            }
            return {
              ...asset,
              ...marketplaceResume,
              marketplace: true,
            }
          }
        }
        return undefined
      })
      .filter(Boolean) as MarketplaceAssetType[]

    return marketplaceAssets
      ? [
          ...marketplaceAssets,
          ...assets!.filter((a) => {
            const assetHasMarketplaceOffer = marketplaceAssets.find(
              (m) => m.id === a.id
            )
            return !assetHasMarketplaceOffer
          }),
        ]
      : undefined
  }, [assets, currencies, gallery, salesResume])

  const paginable = useLocalPagination(items)

  useEffect(() => {
    paginable.getPage(0, itemsPerPage * 2)
  }, [items])

  return paginable
}
