import React, { ElementRef, ForwardedRef, forwardRef } from 'react'
import { UserProfileViewProps } from './UserProfile.types'
import UncontrolledTransition from '@onepercentio/one-ui/dist/components/UncontrolledTransition'
import Styles from './UserProfile.module.scss'

/**
 * Allows the user to manage it's profile
 **/
 function UserProfileView({ children }: UserProfileViewProps, ref: ForwardedRef<ElementRef<typeof UncontrolledTransition>>) {
  return (
    <UncontrolledTransition
      className={Styles.profileRoot}
      contentClassName={Styles.content}
      ref={ref}>
      {children as any}
    </UncontrolledTransition>
  )
}
export default forwardRef(UserProfileView)