import { useState } from 'react'
import View from './Communication.view'
import { CommunicationProps } from './Communication.types'
import { useUser } from 'core/logic/user'
import { useTenant } from 'core/logic/tenant/tenant.hook'

export default function CommunicationLogic({}: CommunicationProps) {
  const { tenant } = useTenant()
  const { profile, setAcceptDataUsage, cancelAccount } = useUser()
  const [acceptDataUsage, setLocal] = useState<boolean>(() => {
    const previousAcceptances =
      profile!.personalData?.tenants?.[process.env.REACT_APP_TENANT].dataConsent
    if (!previousAcceptances) return false
    return previousAcceptances[previousAcceptances.length - 1].value
  })

  return (
    <View
      onDataUsage={(a) => {
        setAcceptDataUsage(a).catch(() => setLocal(acceptDataUsage))
        setLocal(a)
      }}
      dataUsage={acceptDataUsage}
      onCancelAccount={cancelAccount}
    />
  )
}
