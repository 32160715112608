import messagesPt from './pt.ts'
import { mergeDeep } from '@apollo/client/utilities'
import { DeepPartial } from '@onepercentio/one-ui/dist/types'

const messages = {
  'pt-BR': {
    translations: {
      generic: {
        brandMotto: '',
      },
      offerDetails: {
        goalReached_0: 'Sem cotas reservadas até o momento',
      },
      home: {
        banner: {
          title: 'INVISTA COM A OKEN',
          subtitle:
            'Tokenized assets marketplace customized for your community',
          v2: {
            brand: 'oken',
          },
        },
        sections: {
          gallery: {
            heading: 'Conheça os emissores de token',
            title: 'Emissores de tokens',
            action: {
              seeAll: 'Ver todos os emissores',
            },
          },
          create: {
            title: '',
          },
          brand: {
            description: `Compensamos todas as emissões de carbono da plataforma e dos ativos comercializados.

A Oken também opera em blockchains que são eficientes nas suas emissões de gás de efeito estufa.`,
          }
        },
      },
      allGalleries: {
        title: 'Emissores de tokens',
        sections: {
          highlight: {
            title: 'Emissores de tokens em destaque',
          },
          createYourOwn: {
            title: '',
          },
        },
      },
      galleryDetails: {
        noMarket: 'Itens de emissores',
        noAvailable: 'Fique de olho!\nEm breve teremos ofertas deste emissor',
      },
      topbar: {
        menus: {
          galleries: 'EMISSORES',
          about: "SOBRE A OKEN"
        },
      },
    },
  },
} satisfies {
  'pt-BR': DeepPartial<import('./pt').RarumIntlShape>
}

export type OkenIntlShape = (typeof messages)['pt-BR']
export type AllMessagesIntlShape = OkenIntlShape &
  typeof import('translate/languages/async/cvm88/pt')['default']['pt-BR']
export default mergeDeep(messagesPt, messages)
